import React from 'react';

function BankIcon({ className, color = "currentColor" }) {
  return (
    <svg
      className={className}
      width="29"
      height="17"
      viewBox="0 0 29 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.02697 7.01647L14.3991 1.28718L23.7712 7.01647H5.02697Z"
        fill="none"
        stroke={color} // Use dynamic color here
        strokeWidth="1.45241"
      />
      <rect
        x="7.40806"
        y="8.63755"
        width="13.9817"
        height="5.19807"
        stroke={color} // Use dynamic color here
        strokeWidth="0.16768"
      />
      <rect
        x="7.32422"
        y="8.55371"
        width="1.6237"
        height="5.36575"
        rx="0.811848"
        fill={color} // Use dynamic color here
      />
      <rect
        x="13.5869"
        y="8.55371"
        width="1.6237"
        height="5.36575"
        rx="0.811848"
        fill={color} // Use dynamic color here
      />
      <rect
        x="19.8496"
        y="8.55371"
        width="1.6237"
        height="5.36575"
        rx="0.811848"
        fill={color} // Use dynamic color here
      />
      <rect
        x="4.88844"
        y="15.1658"
        width="18.7885"
        height="1.4185"
        rx="0.709252"
        fill={color} // Use dynamic color here
        stroke={color} // Use dynamic color here
        strokeWidth="0.231957"
      />
    </svg>
  );
}

export default BankIcon;
