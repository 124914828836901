import React from 'react';

export default function Table({
    headers,
    rows,
    tableClasses,
    tableHeaderClasses,
    tableCellClasses,
}) {
    const renderRow = (cells, colSpan = [], rowIndex) => (
        <tr key={`row-${rowIndex}`}>
            {cells.map((cell, cellIndex) => (
                <td
                    key={`row-${rowIndex}-cell-${cellIndex}`}
                    colSpan={colSpan[cellIndex] || 1}
                    className={tableCellClasses}
                >
                    {cell}
                </td>
            ))}
        </tr>
    );

    const renderHeaders = () => (
        <thead>
            <tr>
                {headers.map((header, index) => (
                    <th
                        key={`header-${index}`}
                        colSpan={header.colSpan || 1}
                        className={tableHeaderClasses}
                    >
                        {header.title}
                    </th>
                ))}
            </tr>
        </thead>
    );

    return (
        <table className={tableClasses}>
            {renderHeaders()}
            <tbody>
                {rows.map((row, index) => renderRow(row.cells, row.colSpan, index))}
            </tbody>
        </table>
    );
}
