import { Button } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import countLogo from '../../../assets/Frame 1564.svg';
import styles from './Students.module.css';

function Students() {
  const [students, setStudents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const token = localStorage.getItem("accessToken");

  // Function to open the dialog
  const handleClickOpen = () => {
    setOpen(true);
  };

  // Function to close the dialog
  const handleClose = () => {
    setOpen(false);
  };

  // Function to fetch student data with pagination
  const fetchStudents = async (page) => {
    setLoading(true);
    setError(null);

    try {
      const response = await axios.get(`https://backend.nextera.education/payment/?page=${page}`, {
        headers: {
          'accept': 'application/json',
          'Authorization': `jwt ${token}`,
          'X-CSRFToken': 'nO8dBHNLqsmcdif8GbdiGoG2BZ1khIqvrWYhYuYhPhl04DRtiuqKh47Nn4dpfG9p',
        },
      });
      setStudents(response.data.results);
      setTotalPages(Math.ceil(response.data.count / 20)); // Assuming 10 items per page
    } catch (err) {
      setError('Failed to fetch data');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchStudents(page);
  }, [page]);

  // Pagination handler
  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= totalPages) {
      setPage(newPage);
    }
  };

  // Function to download CSV file
  const handleDownloadCSV = async () => {
    try {
      const response = await axios.get('https://backend.nextera.education/payment-csv/', {
        headers: {
          'accept': 'application/json',
          'Authorization': `jwt ${token}`,
          'X-CSRFToken': '9l2Htga9qNOrt4xUJDD1nouFlyL2DdlFdtSLQ3lFPCNfkp9flWQtY4Vq7DX7Bb4z',
        },
        responseType: 'blob', // Important for file download
      });

      // Create a link to download the file
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'payments.csv'); // File name
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link); // Cleanup
    } catch (err) {
      setError('Failed to download CSV file');
    }
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div className={styles.dashboardContainer}>
      {/* Main Content */}
      <div className={styles.mainContent}>
        {/* Stats Card */}
        <div className={styles.cardStatePayment}>
          <div className={styles.statsCard}>
            <div>
              <h2>{students.length}</h2>
              <p>Payments</p>
            </div>
            <img src={countLogo} alt="countLogo" />
          </div>
          {/* Download CSV Button */}
          <Button onClick={handleDownloadCSV} variant="contained" color="primary" style={{ marginLeft: '20px' }}>
            Download CSV
          </Button>
        </div>

        {/* Student Table */}
        <table className={styles.studentTable}>
          <thead>
            <tr>
              <th>Student Name</th>
              <th>Email</th>
              <th>Program</th>
              <th>Date paid</th>
              <th>Payment method</th>
              <th>Transaction Id</th>
              <th>Status</th>
              <th>Price</th>
            </tr>
          </thead>
          <tbody>
            {students.map((student) => (
              <tr key={student.id}>
                <td>{`${student.student.first_name} ${student.student.last_name}`}</td>
                <td>{student.student.email}</td>
                <td>{student.program}</td>
                <td>{student.date_paid}</td>
                <td>{student.payment_method}</td>
                <td>{student.transactionId}</td>
                <td>{student.status}</td>
                <td>{student.amount}</td>
              </tr>
            ))}
          </tbody>
        </table>

        {/* Pagination Controls */}
        <div className={styles.pagination}>
          <button onClick={() => handlePageChange(page - 1)} disabled={page === 1}>
            Previous
          </button>
          <span>Page {page} of {totalPages}</span>
          <button onClick={() => handlePageChange(page + 1)} disabled={page === totalPages}>
            Next
          </button>
        </div>
      </div>
    </div>
  );
}

export default Students;
