import React, { useState } from 'react';
import arrowImage from '../../../assets/ArrowRight.png';

export default function CourseOverview() {
    const [activeIndex, setActiveIndex] = useState(null);

    const courseData = [
        {
            day: "Day 1",
            title: "Introduction to AI and Its Impact",
            totalTime: "3 Hours",
            description:
                "On Day 1, participants will explore the basics of AI, its societal impacts, and how it influences everyday life.",
            topics: [
                "Definition and Basics of AI.",
                "Types of AI: Narrow vs. General AI.",
                "Common AI Examples: Virtual assistants, search engines, and recommendation systems.",
                "The role of AI in improving safety (e.g., navigation apps) and convenience.",
                "Pros of AI: Efficiency, accessibility, improved decision-making.",
                "Challenges: Privacy, bias, and potential job automation.",
            ],
            activities: [
                "Interactive Brainstorm - Students share examples of AI they encounter daily and discuss how it affects their lives.",
                "Video Quiz - Watch a short video about AI applications and take a quiz to reinforce learning.",
                "Group Discussion - Students discuss the pros and cons of AI and how it can impact society positively and negatively.",
            ],
        },
        {
            day: "Day 2",
            title: "Practical AI Tools and Applications",
            totalTime: "3 Hours",
            description:
                "On Day 2, participants will use AI tools like Google Lens and AI art generators to gain practical experience.",
            topics: [
                "Basics of Machine Learning and its importance in AI.",
                "Types of Machine Learning: Supervised, unsupervised, and reinforcement learning.",
                "AI-powered tools for productivity, such as Grammarly, Google Assistant, and voice-to-text applications.",
                "Image recognition and translation apps, and how they can aid in learning.",
                "Introduction to AI tools in art, music, and content creation (e.g., AI art generators, music composition tools).",
                "How AI is used in photography and video editing.",
            ],
            activities: [
                "Simple Demo with Teachable Machine - Students learn how to train a model to recognize objects or sounds.",
                "Hands-On Practice - Students practice using tools like Google Translate (for translation) and Google Lens (for image recognition).",
                "Creative Project - Students use a basic AI tool (like an AI drawing generator) to create artwork, demonstrating AI’s potential in creative expression.",
            ],
        },
        {
            day: "Day 3",
            title: "AI for Career Development and Future Opportunities",
            totalTime: "3 Hours",
            description:
                "On Day 3, participants will explore AI career opportunities and understand responsible AI practices.",
            topics: [
                "Overview of AI-related careers: Data scientists, AI engineers, AI ethics consultants.",
                "Required skills: Programming, problem-solving, critical thinking.",
                "How basic AI knowledge can benefit all careers, from marketing to healthcare.",
                "Importance of digital literacy and data interpretation.",
                "Ethical considerations: Bias in AI, data privacy, and the importance of responsible AI use.",
            ],
            activities: [
                "Career Brainstorm - Students map out which AI-related career paths align with their interests.",
                "AI Skill Building Exercise - Students work in groups to discuss how AI could be used in different fields (e.g., AI in journalism for research or AI in medicine for diagnostics).",
                "Scenario Workshop - Students analyze scenarios (e.g., AI in hiring, AI surveillance) and discuss responsible use and potential impacts.",
            ],
        },
    ];

    const toggleAccordion = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    return (
        <div className="bg-[#F5F5F5] p-6 rounded-lg shadow-lg">
            <div>
                {courseData.map((course, index) => (
                    <div
                        key={index}
                        className="bg-white mb-4 rounded-lg shadow-md"
                    >
                        <div
                            className="flex justify-between items-center px-6 py-4 cursor-pointer "
                            onClick={() => toggleAccordion(index)}
                        >
                            <div className="flex justify-between w-full">
                                <h3 className="text-lg font-bold text-[#111927]">
                                    {course.day}: {course.title}
                                </h3>
                                <span className="text-[#40A353] font-medium mr-1">
                                    <strong className="text-[#4E5566] ">Total Time:    </strong>
                                    {course.totalTime}
                                </span>
                            </div>
                            <div
                                className={`transition-transform duration-300 ${activeIndex === index ? "rotate-180" : ""
                                    }`}
                            >
                                {/* Arrow Icon */}
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-6 w-6 text-[#111927]"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M19 9l-7 7-7-7"
                                    />
                                </svg>
                            </div>
                        </div>
                        {activeIndex === index && (
                            <div className="px-6 py-4">
                                {/* <p className="text-lg mb-4">{course.description}</p> */}
                                <h4 className="text-lg font-bold mb-2">Topics Covered:</h4>
                                <ul className=" pl-6 mb-4">
                                    {course.topics.map((topic, i) => (
                                        <div className='flex my-3 items-start'>
                                            <img className='mr-4' src={arrowImage} alt="" srcset="" />
                                            <li key={i} className="text-base text-[#4E5566]">
                                                {topic}
                                            </li>
                                        </div>
                                    ))}
                                </ul>
                                <h4 className="text-lg font-bold mb-2">Activities:</h4>
                                <ul className=" pl-6">
                                    {course.activities.map((activity, i) => (
                                        <div className='flex my-3 items-start'>
                                            <img className='mr-4' src={arrowImage} alt="" srcset="" />
                                            <li key={i} className="text-base text-[#4E5566]">
                                                {activity}
                                            </li>
                                        </div>

                                    ))}
                                </ul>
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
}
