import React, { useState } from 'react';
import image1 from '../../../assets/Background (1).png';
import image2 from '../../../assets/Background (2).png';
import image3 from '../../../assets/Background (3).png';
import image4 from '../../../assets/Background (4).png';


function MaterialsNeeded() {
  const [isOpen, setIsOpen] = useState(false);

  const materials = [
    {
      img: image1,
      description: "Projector and screen for presentations.",
    },
    {
      img: image2, // Replace with actual SVG or image
      description: "Laptops/tablets for hands-on activities.",
    },
    {
      img: image3, // Replace with actual SVG or image
      description: "Access to simple AI tools like Teachable Machine and Google Lens.",
    },
    {
      img: image4, // Replace with actual SVG or image
      description: "Handouts on AI careers, applications, and ethical considerations.",
    },
  ];

  const toggleAccordion = () => setIsOpen((prev) => !prev);

  return (
    <div className="bg-[#F5F5F5] p-6 rounded-lg shadow-lg">
      <div className="bg-[#FFFFFF] px-3 sm:px-8 py-5 rounded-lg">
        <div
          className="flex justify-between items-center cursor-pointer"
          onClick={toggleAccordion}
        >
          <h2 className=" font-bold text-[#111927]">Materials Needed</h2>
          <div
            className={`transition-transform duration-300 ${isOpen ? "rotate-180" : ""
              }`}
          >
            {/* Arrow Icon */}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6 text-[#111927]"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M19 9l-7 7-7-7"
              />
            </svg>
          </div>
        </div>
        {isOpen && (
          <div className="mt-4">
            <div className="flex flex-wrap justify-between">
              {materials.map((material, index) => (
                <div
                  key={index}
                  className="flex flex-col items-center justify-center  rounded-lg p-2 md:w-[25%] w-[100%] shadow-sm"
                >
                  <div className="flex items-center justify-center text-[#40A353] w-16 h-16 rounded-full mb-4 text-3xl">
                    <img src={material.img} alt="" srcset="" />
                  </div>
                  <p className="text-center text-base font-medium text-[#111927]">
                    {material.description}
                  </p>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default MaterialsNeeded;
