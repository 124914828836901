import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useFormik } from 'formik';
import * as yup from 'yup';
import arrow from '../../../assets/Arrow 1.svg';
import styles from './CreateProgram.module.css';
import { useNavigate } from 'react-router-dom';
import { createProgram } from '../../../Service/Api';
import DOMPurify from 'dompurify';

export default function CreateProgram() {
    const [overview, setOverview] = useState('');
    const [learningObjectives, setLearningObjectives] = useState(['']);
    const [backendErrors, setBackendErrors] = useState({});
    const navigate = useNavigate();

    const handleProgramNavigate = () => {
        navigate('/admin-dashboard/programs');
    };

    // Add a new objective field
    const addObjective = () => {
        setLearningObjectives([...learningObjectives, '']);
    };

    // Remove an objective field
    const removeObjective = (index) => {
        const newObjectives = learningObjectives.filter((_, i) => i !== index);
        setLearningObjectives(newObjectives);
    };

    // Update the specific objective value
    const handleObjectiveChange = (value, index) => {
        const newObjectives = [...learningObjectives];
        newObjectives[index] = value;
        setLearningObjectives(newObjectives);
    };

    const validationSchema = yup.object({
        programTitle: yup.string().required('Program title is required'),
        programSubTitle: yup.string().required('Program subtitle is required'),
        programPrice: yup.number().required('Program price is required').positive(),
        numLessons: yup.number().required('Number of lessons is required').integer().positive(),
        duration: yup.string()
            .required('Program duration is required')
            .matches(/^(\d+ )?(\d{1,2}:[0-5][0-9]:[0-5][0-9](\.\d{1,6})?)$/, 'Invalid duration format'),
        language: yup.string().required('Language is required'),
        universityTitle: yup.string().required('University title is required'),
        programImage: yup.mixed().required('Program image is required'), // For image upload
        universityImage: yup.mixed().required('University image is required'), // For image upload
    });

    // Formik setup
    const formik = useFormik({
        initialValues: {
            programTitle: '',
            programSubTitle: '',
            programPrice: '',
            numLessons: '',
            duration: '',
            language: '',
            universityTitle: '',
            programImage: null,
            universityImage: null,
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            const programData = new FormData();
            programData.append("title", values.programTitle);
            programData.append("subtitle", values.programSubTitle);
            programData.append("price", String(values.programPrice));
            programData.append("num_lessons", values.numLessons);
            programData.append("duration", values.duration);
            programData.append("language", values.language);
            programData.append("university_title", values.universityTitle);
            programData.append("overview", overview);
            programData.append("program_image", values.programImage);
            programData.append("university_image", values.universityImage);

            // Sanitize or convert to plain text as needed
            const sanitizedOverview = DOMPurify.sanitize(overview, { ALLOWED_TAGS: [] }); // removes all tags
            programData.append("overview", sanitizedOverview);

            learningObjectives.forEach((objective, index) => {
                const sanitizedObjective = DOMPurify.sanitize(objective, { ALLOWED_TAGS: [] });
                programData.append(`learning_objectives[${index}]`, sanitizedObjective);
            });
            // learningObjectives.forEach((objective) => {
            //     programData.append('learning_objectives', objective);
            // });


            try {
                await createProgram(programData);
                // alert("Program created successfully");
                navigate('/admin-dashboard/programs');
            } catch (error) {
                console.error("Failed to create program:", error);
                if (error.response && error.response.data) {
                    setBackendErrors(error.response.data);
                } else {
                    alert("Failed to create program");
                }
            }
        },
    });

    const handleOverviewChange = (value) => setOverview(value);

    return (
        <div className={styles.createProgramContainer}>
            <div className={styles.backToPrograms} onClick={handleProgramNavigate}>
                <img src={arrow} alt='arrow' />
                <h2>Back to Programs</h2>
            </div>

            <form className={styles.formContainer} onSubmit={formik.handleSubmit}>

                <div className={styles.replaceImgContainer}>
                    <div className={styles.replaceImgCard}>
                        {/* <h2>University Image</h2>
                        <div className={styles.replaceImg}>
                            <img src={imgIcon} alt='imgIcon' />
                            <h3>
                                <strong>Click to replace </strong> or drag and drop
                            </h3>
                            <h4>SVG, PNG, JPG or GIF (max. 400 x 400px)</h4>
                        </div> */}
                        <label htmlFor='programImage'>Program Image:</label>
                        <input
                            id='programImage'
                            name='programImage'
                            type='file'
                            accept="image/*"
                            onChange={(event) => formik.setFieldValue('programImage', event.currentTarget.files[0])}
                        />
                        {formik.errors.programImage && formik.touched.programImage && (
                            <div className={styles.errorText}>{formik.errors.programImage}</div>
                        )}
                    </div>
                    <div className={styles.replaceImgCard}>
                        {/* <h2>Program Image</h2>
                        <div className={styles.replaceImg}>
                            <img src={imgIcon} alt='imgIcon' />
                            <h3>
                                <strong>Click to replace </strong> or drag and drop
                            </h3>
                            <h4>SVG, PNG, JPG or GIF (max. 400 x 400px)</h4>
                        </div> */}
                        <label htmlFor='universityImage'>University Image:</label>
                        <input
                            id='universityImage'
                            name='universityImage'
                            type='file'
                            accept="image/*"
                            onChange={(event) => formik.setFieldValue('universityImage', event.currentTarget.files[0])}
                        />
                        {formik.errors.universityImage && formik.touched.universityImage && (
                            <div className={styles.errorText}>{formik.errors.universityImage}</div>
                        )}
                    </div>
                </div>
                <div className={styles.inputLabelFields}>
                    {/* Program Title */}
                    <label htmlFor='programTitle'>Program Title:</label>
                    <input
                        id='programTitle'
                        name='programTitle'
                        type='text'
                        placeholder='Title'
                        value={formik.values.programTitle}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                    />
                    {formik.touched.programTitle && formik.errors.programTitle && (
                        <div className={styles.errorText}>{formik.errors.programTitle}</div>
                    )}
                </div>

                <div className={styles.inputLabelFields}>
                    {/* Program Sub Title */}
                    <label htmlFor='programSubTitle'>Program Sub Title:</label>
                    <input
                        id='programSubTitle'
                        name='programSubTitle'
                        type='text'
                        placeholder='SubTitle'
                        value={formik.values.programSubTitle}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                    />
                    {formik.touched.programSubTitle && formik.errors.programSubTitle && (
                        <div className={styles.errorText}>
                            {formik.errors.programSubTitle}
                        </div>
                    )}
                </div>

                <div className={styles.inputFields}>
                    <div className={styles.inputLabelFieldsSmall}>
                        {/* Program Price and Lessons */}
                        <label htmlFor='programPrice'>Program Price:</label>
                        <input
                            id='programPrice'
                            name='programPrice'
                            type='number'
                            placeholder='Price'
                            value={formik.values.programPrice}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                        {formik.touched.programPrice && formik.errors.programPrice && (
                            <div className={styles.errorText}>{formik.errors.programPrice}</div>
                        )}
                    </div>

                    <div className={styles.inputLabelFieldsSmall}>
                        {/* Program Duration and Language */}
                        <label htmlFor='programLessons'>Number of lessons:</label>
                        <input
                            id='numLessons'
                            name='numLessons'
                            type='number'
                            placeholder='No. of lessons'
                            value={formik.values.numLessons}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                        {formik.touched.programLessons && formik.errors.programLessons && (
                            <div className={styles.errorText}>
                                {formik.errors.programLessons}
                            </div>
                        )}
                    </div>
                </div>

                <div className={styles.inputFields}>
                    <div className={styles.inputLabelFieldsSmall}>
                        {/* Program Duration and Language */}
                        <label htmlFor='duration'>Program Duration (HH:MM:SS):</label>
                        <input
                            id='duration'
                            name='duration'
                            type='text'
                            placeholder='e.g., 02:30:00'
                            value={formik.values.duration}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                        {formik.touched.duration && formik.errors.duration && (
                            <div className={styles.errorText}>{formik.errors.duration}</div>
                        )}
                    </div>
                    <div className={styles.inputLabelFieldsSmall}>
                        <label htmlFor='language'>Language:</label>
                        <input
                            id='language'
                            name='language'
                            type='text'
                            placeholder='Language'
                            value={formik.values.language}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                        {formik.touched.language && formik.errors.language && (
                            <div className={styles.errorText}>{formik.errors.language}</div>
                        )}
                    </div>
                </div>

                <div className={styles.inputLabelFields}>
                    {/* University Name */}
                    <label htmlFor='universityTitle'>University Name:</label>
                    <input
                        id='universityTitle'
                        name='universityTitle'
                        type='text'
                        placeholder='University Name'
                        value={formik.values.universityTitle}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                    />
                    {formik.touched.universityTitle && formik.errors.universityTitle && (
                        <div className={styles.errorText}>{formik.errors.universityTitle}</div>
                    )}
                </div>

                <div className={styles.quillContainer}>
                    {/* Overview */}
                    <label htmlFor='overview'>Overview:</label>
                    <ReactQuill
                        theme='snow'
                        value={overview}
                        onChange={handleOverviewChange}
                        placeholder='Enter program overview...'
                        className={styles.reactQuill}
                    />

                    {/* Learning Objectives */}
                    <label>Learning Objectives:</label>
                    {learningObjectives.map((objective, index) => (
                        <div key={index} className={styles.objectiveContainer}>
                            <ReactQuill
                                theme="snow"
                                value={objective}
                                onChange={(value) => handleObjectiveChange(value, index)}
                                placeholder={`Objective ${index + 1}`}
                            />
                            <button type="button" onClick={() => removeObjective(index)} className={styles.removeObjective}>
                                Remove Objective
                            </button>
                        </div>
                    ))}
                    <button type="button" onClick={addObjective} className={styles.addObjective}>Add Objective</button>
                </div>

                {/* Submit Button */}
                <button type='submit' className={styles.submitButton}>
                    Create Program
                </button>
                {backendErrors && (
                    <div className={styles.errorContainer}>
                        {Object.entries(backendErrors).map(([field, messages]) => (
                            <div key={field}>
                                <strong>{field}:</strong>
                                {messages.map((message, index) => (
                                    <div key={index}>{message}</div>
                                ))}
                            </div>
                        ))}
                    </div>
                )}
            </form>
        </div>
    );
}
