import React from 'react';
import Table from '../../../Components/Table/Table';

export default function CourseOverview() {
    const tableHeaderClasses = "border border-[#FFFFFF] bg-[#40A353] text-[#FFFFFF] text-xl font-bold p-4";
    const tableCellClasses = "border-4 border-[#FFFFFF] bg-[#F3F3F3] text-[#0E1726] text-center text-base font-medium p-4";
    const tableClasses = "table-auto border-collapse border border-[#FFFFFF] w-full";

    // Data for the tables
    const headersFirst = [
        { title: "Comprehensive Skills in Core Business Practices and Principles", colSpan: 4 },
    ];

    const rowsFirst = [
        { cells: ["Finance", "Leadership", "Marketing", "Operations"], colSpan: [1, 1, 1, 1] },
        { cells: ["Strategy", "Innovation", "Capstone"], colSpan: [1, 1, 2] },
    ];

    const headersSecond = [
        { title: "Electives", colSpan: 4 },
    ];

    const rowsSecond = [
        { cells: ["Sustainability", "Strategy", "E-Commerce", "Operations"], colSpan: [1, 1, 1, 1] },
        { cells: ["Digital Transformation", "Data Science", "Leadership", "Global Business"], colSpan: [1, 1, 1, 1] },
        { cells: ["Management"], colSpan: [4] },
    ];

    return (
        <div className='bg-[#F5F5F5] p-3 sm:p-6 rounded-b-3xl '>
            <div className='bg-[#FFFFFF] collapse collapse-arrow px-3 sm:px-8 py-5 rounded-lg mb-6'>
                <input type="radio" name="my-accordion-2" defaultChecked />
                <h2 className='text-xl collapse-title font-bold text-[#111927] my-6'>Explore key topics and benefits</h2>
                <p className='text-lg collapse-content font-medium text-[#4E5566]'>
                    Our MBA provides skills to drive your career to new levels How will
                    your CV stand out in an ever more crowded job market? If you’re not
                    thinking about adding an MBA to yours, you should be: applications to
                    online MBA programs increased by over 43% in 2020. At Nexford, as an
                    online mba student, you have the flexibility to focus your online MBA
                    program on a specific field of expertise if you’d like to. Choose from
                    six online MBA specializations: Sustainability, International
                    Business, ECommerce, Fintech & Blockchain, Artificial Intelligence,
                    and Cybersecurity — or choose your own electives. Our MBA program,
                    tailored for seasoned learners, cultivates strategic thinkers who can
                    navigate complex business landscapes and adapt to evolving global
                    economies. Master the essential skills of leadership, innovation, and
                    sustainability to propel your career forward – regardless of location.
                    Discover our MBA courses below.
                </p>
            </div>

            <div className='bg-[#FFFFFF] collapse collapse-arrow px-3 sm:px-8 py-5 rounded-lg'>
                <input type="radio" name="my-accordion-2" />

                <h2 className='text-2xl collapse-title font-bold text-[#111927] mt-4 mb-8'>MBA Program</h2>
                <div className='collapse-content'>
                    <Table
                        headers={headersFirst}
                        rows={rowsFirst}
                        tableClasses={tableClasses}
                        tableHeaderClasses={tableHeaderClasses}
                        tableCellClasses={tableCellClasses}
                    />
                    <Table
                        headers={headersSecond}
                        rows={rowsSecond}
                        tableClasses={tableClasses}
                        tableHeaderClasses={tableHeaderClasses}
                        tableCellClasses={tableCellClasses}
                    />
                </div>
            </div>
        </div>
    );
}
