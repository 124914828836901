import React from 'react';
import Table from '../../../Components/Table/Table';
import ProgramCard from '../../../Components/ProgramCard/ProgramCard';

const admissionRequirements = [
    {
        title: 'Explore key topics and benefits',
        description:
            'A photo or scan of a government-issued form of identification and a passport-style photo or selfie.',
        courses: [
            ' Provide proof of a bachelor’s degree from an accredited institution with an official diploma and transcript in english. If your documents aren’t in English, our team will handle the translation for you.',
            'We may ask you to certify that your undergraduate degree meets the university’s qualification standard and refer you to an external agency to evaluate your credit',
        ],
    },
];

export default function AdmissionRequirements() {
    const tableHeaderClasses =
        'border border-[#FFFFFF] bg-[#40A353] text-[#FFFFFF] text-xl font-bold p-4';
    const tableCellClasses =
        'border-4 border-[#FFFFFF] bg-[#F3F3F3] text-[#0E1726] text-center text-base font-medium p-4';
    const tableClasses =
        'table-auto border-collapse border border-[#FFFFFF] w-full';

    // Data for the tables
    const headers = [
        { title: 'English Proficiency Qualification', colSpan: 1 },
        { title: 'Graduates', colSpan: 1 },
    ];

    const rows = [
        { cells: ['Minimum Score Required'], colSpan: [2] },
        {
            cells: [
                'Test of English as a Foreign Language (TOEFL) Paper-based Test (PBT)',
                '60',
            ],
            colSpan: [1, 1],
        },
        { cells: ['TOEFL Internet-based Test (iBT)', '71'], colSpan: [1, 1] },
        {
            cells: ['International English Language Testing System (IELTS)', '6.5'],
            colSpan: [1, 1],
        },
        {
            cells: ['Pearson Test of English (PTE) Academic Test', '50'],
            colSpan: [1, 1],
        },
        { cells: ['Eiken English Proficiency Exam', 'Pre-1'], colSpan: [1, 1] },
        { cells: ['Duolingo English Test', '100'], colSpan: [1, 1] },
        {
            cells: [
                'Exams identified within the Common European Framework of Reference (CEFR)',
                'B-2',
            ],
            colSpan: [1, 1],
        },
    ];

    return (
        <div className='bg-[#F5F5F5] p-3 sm:p-6 rounded-b-3xl '>
            {admissionRequirements.map((item, index) => (
                <ProgramCard
                    key={`admission-${index}`}
                    title={item.title}
                    description={item.description}
                    subTitle='Proof of graduation :'
                    courses={item.courses}
                    isDefaultChecked={index === 0}
                />
            ))}

            <div className='bg-[#FFFFFF] collapse collapse-arrow px-4 sm:px-8 py-5 rounded-lg'>
                <input type="radio" name="my-accordion-2" />
                <h2 className='text-xl collapse-title font-semibold text-[#1D2026] mt-4 mb-8'>
                    English Proficiency
                </h2>
                <div className="collapse-content">
                    <Table
                        headers={headers}
                        rows={rows}
                        tableClasses={tableClasses}
                        tableHeaderClasses={tableHeaderClasses}
                        tableCellClasses={tableCellClasses}
                    />
                </div>
            </div>
        </div>
    );
}
