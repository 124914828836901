import React from 'react';
import ProgramCard from '../../../Components/ProgramCard/ProgramCard';

const specializationsData = [
    {
        title: 'Sustainability',
        description:
            'Crops, food production and water supplier under strain as human populations increase. Design a practical approach to addressing future wellness programs by recognizing cultural differences, the challenges of technology initiatives, and occupational healthcare shortages.',
        courses: ['Food and Agribusiness', 'Renewable Energy', 'Managing Healthcare'],
    },
    {
        title: 'International Business',
        description:
            'You have a great idea: does it translate across cultures? Unpack the cultural complexities and get to grips with doing business in high-growth hot zones.',
        courses: [
            'Doing Business in India',
            'Doing Business in China',
            'Doing Business in Sub Saharan Africa',
        ],
    },
    {
        title: 'E-Commerce',
        description:
            'It’s not only the Amazons of this world that have to think about bricks and clicks. B2B, B2G, and B2C across the world need a strategic handle on the future of e-commerce.',
        courses: [
            'Enabling E-Commerce and Digital Strategy',
            'Policy and Regulatory Enablement of E-commerce',
            'Operations Digital Transformation',
        ],
    },
    {
        title: 'Artificial Intelligence',
        description:
            'The robots aren’t coming; they’re already here. AI and automation promises considerable economic benefits. How can you ensure each link of the value chain is marching to the same beat?',
        courses: [
            'Artificial Intelligence',
            'Data Sciences for Decision Making',
            'Cybersecurity Leadership',
        ],
    },
    {
        title: 'Cybersecurity',
        description:
            'From cyber war rooms to hackers, cybersecurity is top of every business agenda. Discover IoT ecosystems to minimize technical and product risk using Agile and Lean frameworks.',
        courses: [
            'Internet of Things',
            'Cybersecurity Leadership',
            'Product Management with Agile and Lean',
        ],
    },
];

export default function Specializations() {

    return (
        <div className='bg-[#F5F5F5] p-4 sm:p-6 rounded-b-3xl '>
            {specializationsData.map((item, index) => (
                <ProgramCard
                    key={index}
                    title={item.title}
                    description={item.description}
                    subTitle='Courses:'
                    courses={item.courses}
                    isDefaultChecked={index === 0}
                />
            ))}
        </div>
    );
}
