import React, { useState, useEffect } from 'react';
import nextEraLogo from '../../assets/nextEra.svg';
import { FaBars, FaTimes } from 'react-icons/fa';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import styles from './Navbar.module.css';

export default function Navbar() {
    const [isMobile, setIsMobile] = useState(false);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();

    // Check if user is logged in by checking for tokens in local storage
    useEffect(() => {
        const accessToken = localStorage.getItem('accessToken');
        setIsLoggedIn(!!accessToken); // Set isLoggedIn to true if token exists
    }, [location.pathname]); // Re-run this check whenever the path changes

    const handleMobileMenu = () => {
        setIsMobile(!isMobile);
    };

    // Helper function to check if the link is active
    const isActive = (path) => location.pathname === path;

    // Logout function
    const handleLogout = () => {
        // Remove tokens from local storage
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('is_staff');
        localStorage.removeItem('first_name');
        setIsLoggedIn(false); // Update state to reflect logout
        navigate('/login'); // Redirect to home page
    };
    const is_staff = localStorage.getItem("is_staff") === "true";  // Convert to boolean

    return (
        <div className={styles.navbarContainer}>
            <div className={styles.navbarContent}>
                <a href="https://nextera.education/" target="_blank" rel="noreferrer">
                    <img src={nextEraLogo} alt="Nextera Logo" className={styles.navbarLogo} />
                </a>
                <div className={`${styles.navbarNavigation} ${isMobile ? styles.mobileMenu : ''}`}>
                    <Link to="/" className={isActive('/') ? styles.activeLink : ''}><h2>Home</h2></Link>
                    <Link to="/all-universities" className={isActive('/all-universities') ? styles.activeLink : ''}><h2>Universities</h2></Link>
                    <Link to="/all-programs" className={isActive('/all-programs') ? styles.activeLink : ''}><h2>Programs</h2></Link>
                    {is_staff && (<Link to="/admin-dashboard/students" className={isActive('/admin-dashboard/students') ? styles.activeLink : ''}><h2>Dashboard</h2></Link>)}
                    {isLoggedIn ? (
                        <button onClick={handleLogout} className={styles.logoutButton}><h2>Logout</h2></button>
                    ) : (
                        <>
                            <Link to="/login" className={isActive('/login') ? styles.activeLink : ''}><h2>login</h2></Link>
                            <Link to="/register" className={isActive('/register') ? styles.activeLink : ''}><h2>Register</h2></Link>
                        </>
                    )}
                </div>
                <div className={styles.mobileIcon} onClick={handleMobileMenu}>
                    {isMobile ? <FaTimes /> : <FaBars />}
                </div>
            </div>
        </div>
    );
}
