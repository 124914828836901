import React from 'react';
import ArrowRight from '../SVG/ArrowRight';

export default function ProgramCard({ title, description, subTitle, courses, isDefaultChecked }) {
    return (
        <div className='bg-[#FFFFFF] collapse collapse-arrow px-4 sm:px-8 py-5 rounded-lg mb-6'>
            <input type="radio" name="my-accordion-2" defaultChecked={isDefaultChecked} />

            <h2 className='text-xl collapse-title font-bold text-[#1D2026] my-6'>{title}</h2>
            <div className="collapse-content">
                <span className='text-[#0E1726] flex gap-2 text-base font-semibold'>
                    {description}
                </span>
                <div className='bg-[#FAFAFA] rounded-lg py-5 my-4 px-1'>
                    <h2 className='text-base font-semibold text-[#40A353] ml-5 mb-4'>
                        {subTitle}
                    </h2>
                    {courses.map((course, index) => (
                        <span
                            key={index}
                            className='text-[#111927] text-sm font-normal grid grid-flow-col gap-2 w-fit mb-2'
                        >
                            <ArrowRight />
                            {course}
                        </span>
                    ))}
                </div>
            </div>
        </div>
    );
}
