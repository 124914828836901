import React from "react";
import nexFord from '../../assets/nextEra.svg';

function FawryPymentDetailsEgyptian({ finalPrice, totalInstallmentsPaid }) {
  return (
    <div className="border p-6 rounded-lg shadow-md h-[100%]">
      <h3 className="text-lg font-semibold mb-4">Price</h3>
      <div className="border-b pb-4 mb-4">
        <div className="flex justify-between items-center mb-2">
          <div className="flex items-center">
            <img
              src={nexFord}
              alt="University Logo"
              className="w-[100%]  mr-3"
            />
            <div className='w-[100%]'>
              <p className="font-semibold">Enrollment</p>
              {/* <p className="text-sm text-gray-500">MPA Program</p> */}
            </div>
          </div>
          <p className="font-semibold">{finalPrice || "Loading..."} EGP</p>
        </div>
        <div className="flex items-center gap-4">
          <input
            type="text"
            placeholder="Gift or discount code"
            className="flex-1 p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-green-500"
          />
          <button className="bg-gray-300 text-gray-700 py-2 px-4 rounded-md hover:bg-gray-400">
            Apply
          </button>
        </div>
        <p className="text-sm text-gray-500 mt-1">
          Add coupon code (optional). Coupon will be used once it's applied.
        </p>
      </div>
      <div className="flex justify-between items-center text-xl font-semibold">
        <p>Total</p>
        <p>{finalPrice || "Loading..."} EGP</p>
      </div>
      {totalInstallmentsPaid > 0 && (
        <p className="text-sm text-gray-500 mt-2">
          Total Installments Paid: {totalInstallmentsPaid}
        </p>
      )}
    </div>
  );
}

export default FawryPymentDetailsEgyptian;
