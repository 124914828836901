import React, { useState } from 'react';
import IntroProgram from '../../Components/IntroProgram/IntroProgram';
import ProgramPaymentInfo from '../../Components/ProgramPaymentInfo/ProgramPaymentInfo';
import companyLogo from '../../assets/nextEra.svg';
import CourseOverview from './CourseOverview/CourseOverview';
import styles from './IntroductionAi.module.css';
import Tabs from './Tabs/Tabs';
import MaterialsNeeded from './MaterialsNeeded/MaterialsNeeded';


export default function IntroductionAi() {
  const [activeTab, setActiveTab] = useState('overview');

  const tabs = [
    { key: 'overview', title: 'Course Overview' },
    { key: 'materialsNeeded', title: 'Materials Needed' },
  ];

  const renderContent = () => {
    switch (activeTab) {
      case 'overview':
        return <CourseOverview />;
      case 'materialsNeeded':
        return <MaterialsNeeded />;
      default:
        return null;
    }
  };

  return (
    <div className={styles.programMBAContainer}>
      <div className={`sm:flex ${styles.introProgramContainer}`}>
        <IntroProgram
          logo={companyLogo}
          paragraph='Introduction to AI Literacy'
          paragraphClassName="text-5xl my-5"
          studentType="Egyption students"
          studentTypeClassName="text-green-500 text-bold text-5xl"
        />
        <ProgramPaymentInfo programId={13} duration='3 Hours' />
      </div>

      <div className='custom-container'>
        <div className="flex flex-col sm:flex-row my-6 gap-6">
          {/* Tabs Component */}
          <Tabs activeTab={activeTab} setActiveTab={setActiveTab} tabs={tabs} />

          {/* Content */}
          <div className='sm:w-3/4 sm:p-4'>{renderContent()}</div>
        </div>
      </div>
    </div>
  );
}
