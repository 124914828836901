// ProtectedRoute.js
import React from 'react';
import { Navigate } from 'react-router-dom';

// Dummy authentication function (replace with real authentication logic)
const isAuthenticated = () => {
  // Check for user authentication status here, like checking a token in local storage
  return localStorage.getItem('is_staff') === 'true';
};

const ProtectedRoute = ({ children }) => {
  if (!isAuthenticated()) {
    // Redirect to login page if not authenticated
    return <Navigate to="/" />;
  }

  return children;
};

export default ProtectedRoute;
