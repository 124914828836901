import axios from "axios";

export const axiosInterceptor = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  timeout: 10000,
});

axiosInterceptor.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("accessToken");
    if (token) {
      config.headers.Authorization = `jwt ${token}`;
    }

    config.headers.Accept = "application/json";

    return config;
  },

  (error) => {
    console.error("Request Error:", error);
    return Promise.reject(error);
  }
);

axiosInterceptor.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      localStorage.removeItem("accessToken");
      localStorage.removeItem("refreshToken");
      localStorage.removeItem("is_staff");
      localStorage.removeItem("first_name");
      if (window.location.pathname !== "/login") {
        window.location.href = "/login";
      }
    }

    return Promise.reject(error);
  }
);
