import React from 'react'

export default function GraduationCap({ className }) {
    return (
        <svg
            width={25}
            height={23}
            viewBox="0 0 25 23"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <path
                d="M2.20312 8.78125L12.75 3.15625L23.2969 8.78125L12.75 14.4062L2.20312 8.78125Z"
                stroke="white"
                strokeWidth={3}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M18.0234 21.4375V11.5938L12.75 8.78125"
                stroke="white"
                strokeWidth={3}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M20.8359 10.0938V14.8856C20.8362 15.0373 20.7872 15.185 20.6962 15.3063C20.1041 16.0942 17.6062 18.9766 12.75 18.9766C7.89379 18.9766 5.39586 16.0942 4.80377 15.3063C4.71281 15.185 4.66377 15.0373 4.66406 14.8856V10.0938"
                stroke="white"
                strokeWidth={3}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}
