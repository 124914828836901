import React, { useState } from 'react';

function LearningOutcomes() {
  const [activeIndex, setActiveIndex] = useState(null);

  const outcomes = [
    {
      day: "Day 1",
      description: "Understand the basics of AI and its societal impacts.",
      details:
        "On Day 1, participants will explore what AI is, its various types, and how it impacts society, including examples from everyday life.",
      totalTime: "3 Hours",
    },
    {
      day: "Day 2",
      description:
        "Gain hands-on experience with AI tools, exploring AI’s role in daily life and creative fields.",
      details:
        "On Day 2, participants will use AI tools like Google Lens and AI art generators to gain practical experience, focusing on creativity and problem-solving.",
      totalTime: "3 Hours",
    },
    {
      day: "Day 3",
      description:
        "Discover AI career paths and develop an understanding of responsible AI use.",
      details:
        "On Day 3, participants will learn about AI career opportunities, ethical considerations, and the importance of responsible AI practices.",
      totalTime: "3 Hours",
    },
  ];

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="bg-[#F5F5F5] p-6 rounded-lg shadow-lg">
      <div>
        {outcomes.map((outcome, index) => (
          <div
            key={index}
            className="bg-[#FFFFFF] mb-4 rounded-lg shadow-md"
          >
            <div
              className="flex justify-between items-center px-6 py-4 cursor-pointer"
              onClick={() => toggleAccordion(index)}
            >
              <div>
                <h3 className="text-xl font-bold text-[#111927]">
                  {outcome.day}
                </h3>

              </div>
              <div className="flex items-center">
                <span className="text-[#4E5566] text-base mr-4">
                  Total Time:{" "}
                  <span className="text-[#40A353] font-medium">
                    {outcome.totalTime}
                  </span>
                </span>
                <div
                  className={`transition-transform duration-300 ${activeIndex === index ? "rotate-180" : ""
                    }`}
                >
                  {/* Arrow Icon */}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6 text-[#111927]"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M19 9l-7 7-7-7"
                    />
                  </svg>
                </div>
              </div>
            </div>
            {activeIndex === index && (
              <div className="px-6 py-4 text-[#4E5566] text-base">
                {outcome.description}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

export default LearningOutcomes;
