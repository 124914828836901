import React from 'react';
import ArrowRight from '../../../Components/SVG/ArrowRight';

const targetAudience = [
    'The program is ideal for aspiring business leaders',
    'Entrepreneurs',
    'Professionals looking to transition into roles such as business managers, marketing specialists',
    'Financial analysts',
    'It suits individuals looking for a flexible, affordable education that fits around their schedules',
];

export default function CourseOverview() {
    return (
        <div className='bg-[#F5F5F5] p-3 sm:p-6 rounded-b-3xl'>
            <div className="collapse collapse-arrow bg-[#FFFFFF] px-3 sm:px-8 py-5 rounded-lg mb-6">
                <input type="radio" name="my-accordion-2" defaultChecked />
                <h2 className="collapse-title text-xl font-bold text-[#111927] my-6">
                    Explore key topics and benefits
                </h2>
                <div className="collapse-content">
                    <p className="text-lg font-medium text-[#4E5566]">
                        The Bachelor of Business Administration (BBA) at Nexford University is a
                        100% online, flexible program designed to build global business skills. It
                        covers key areas such as financial accounting, strategic management,
                        marketing, and business law, offering a balance of theoretical knowledge
                        and practical skills.
                    </p>
                    <div className="flex flex-wrap sm:flex-nowrap gap-5 my-6 justify-evenly">
                        <div className="bg-[#FAFAFA] w-auto sm:w-2/6 rounded-lg py-5 my-4 px-4">
                            <h3 className="text-base font-bold text-[#40A353] mb-4">
                                Core Courses
                            </h3>
                            <span className="text-[#111927] text-sm">
                                Financial Accounting, Business Law, Marketing Management, Data-Driven
                                Decision Making.
                            </span>
                        </div>
                        <div className="bg-[#FAFAFA] w-auto sm:w-2/6 rounded-lg py-5 my-4 px-4">
                            <h3 className="text-base font-bold text-[#40A353] mb-4">
                                Electives/Specializations
                            </h3>
                            <span className="text-[#111927] text-sm">
                                Artificial Intelligence (AI), Business Analytics, E-Commerce, Digital
                                Marketing.
                            </span>
                        </div>
                        <div className="bg-[#FAFAFA] w-auto sm:w-2/6 rounded-lg py-5 my-4 px-4">
                            <h3 className="text-base font-bold text-[#40A353] mb-4">
                                General Education
                            </h3>
                            <span className="text-[#111927] text-sm">
                                Courses include Intercultural Communication and Business Statistics to
                                develop foundational skills.
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            <div className='collapse collapse-arrow bg-[#FFFFFF] px-3 sm:px-8 py-5 rounded-lg mb-6'>
                <input type="radio" name="my-accordion-2" />
                <h2 className='collapse-title text-xl font-bold text-[#111927] my-6'>
                    Who this course is for
                </h2>
                <div className='space-y-2 collapse-content'>
                    {targetAudience.map((target, index) => (
                        <div key={index} className='flex gap-2'>
                            <div className='flex-shrink-0 w-10 h-10'>
                                <ArrowRight />
                            </div>
                            <span className='text-[#4E5566] text-base font-medium '>
                                {target}
                            </span>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}
