import React, { useState } from "react";
import companyLogo from "../../assets/nexfordLogo.png";
import ApplicationProcess from "../../Components/ApplicationProcess/ApplicationProcess";
import IntroProgram from "../../Components/IntroProgram/IntroProgram";
import ProgramPaymentInfo from "../../Components/ProgramPaymentInfo/ProgramPaymentInfo";
import Tabs from "../ProgramMBA/Tabs/Tabs";
import AdmissionRequirements from './AdmissionRequirements/AdmissionRequirements';
import CourseOverview from "./CourseOverview/CourseOverview";
import Outcomes from "./Outcomes/Outcomes";
import styles from './ProgramBBA.module.css';

export default function ProgramBBA() {
  const [activeTab, setActiveTab] = useState('overview');

  const tabs = [
    { key: 'overview', title: 'Course Overview' },
    { key: 'admission', title: 'Admission Requirements' },
    { key: 'outcomes', title: 'Career Outcomes' },
    { key: 'application', title: 'Application Process' },
  ];

  const renderContent = () => {
    switch (activeTab) {
      case 'overview':
        return <CourseOverview />;
      case 'admission':
        return <AdmissionRequirements />;
      case 'outcomes':
        return <Outcomes />;
      case 'application':
        return <ApplicationProcess />;
      default:
        return null;
    }
  };

  return (
    <div className={styles.programMBAContainer}>
      <div className={`sm:flex ${styles.introProgramContainer}`}>
        <IntroProgram
          logo={companyLogo}
          title="Bachelor of Business Administration (BBA)"
          duration="3 to 4 years to complete depending on the learner's pace"
          credits="120 credits required to graduate"
        />
        <ProgramPaymentInfo programId={11} duration='3 to 4 years' />
      </div>
      <div className='custom-container'>
        <div className="flex flex-col sm:flex-row my-6 gap-6">
          {/* Tabs Component */}
          <Tabs activeTab={activeTab} setActiveTab={setActiveTab} tabs={tabs} />

          {/* Content */}
          <div className='sm:w-3/4 sm:p-4'>{renderContent()}</div>
        </div>
      </div>
    </div>
  );
}
