import React, { useEffect, useState } from 'react';
import calenderProgram from '../../../assets/calenderCard.svg';
import { getPrograms } from '../../../Service/Api';
import AddIcon from '@mui/icons-material/Add';
import styles from './Programs.module.css';
import { useNavigate, Outlet } from 'react-router-dom';

export default function Programs() {
    const [programs, setPrograms] = useState([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    const handleCreateProgram = () => {
        navigate('/admin-dashboard/programs/create');
    };

    // const handleClickOpen = () => {
    //     setOpen(true);
    // };

    useEffect(() => {
        const fetchPrograms = async () => {
            try {
                const programsData = await getPrograms();
                setPrograms(programsData);
            } catch (error) {
                console.error('Error fetching programs:', error);
            } finally {
                setLoading(false);
            }
        };
        fetchPrograms();
    }, []);

    if (loading) return <h1>Loading....</h1>;

    return (
        <div className={styles.dashboardContainer}>
            {/* Main Content */}
            <div className={styles.mainContent}>
                {/* Stats Card */}
                <div className={styles.cardStatePayment}>
                    <div className={styles.statsCard}>
                        <div>
                            <h2>{programs.count}</h2>
                            <p>Created Links</p>
                        </div>
                        <img src={calenderProgram} alt='countLogo' />
                    </div>
                    <button className={styles.addButton} onClick={handleCreateProgram}>
                        <AddIcon />
                        <h3>Create Program</h3>
                    </button>
                </div>

                {/* Student Table */}
                <table className={styles.programsTable}>
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Program Name</th>
                            <th>University</th>
                            <th>Price</th>
                        </tr>
                    </thead>
                    <tbody>
                        {programs.results.map((program) => (
                            <tr key={program.id}>
                                <td>{program.id}</td>
                                <td>{program.title}</td>
                                <td>{program.university_title}</td>
                                <td>{program.price}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <Outlet />
            </div>
        </div>
    );
}
