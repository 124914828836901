import React from 'react';

const cardContents = [
    {
        id: 1,
        title: 'Apply',
        details:
            'Complete the online application anytime. Start date is the 1st of each month.',
    },
    {
        id: 2,
        title: 'Wait for an admissions decision',
        details: 'Typically received within 7 days.',
    },
    {
        id: 3,
        title: 'Enrollment',
        details:
            'Once accepted, students have 30 days to enroll and make their first payment. Online options include local currency payment.',
    },
    {
        id: 4,
        title: 'Complete orientation',
        details:
            'Once you’ve enrolled your first step is joining NXU Orientation. Orientation is designed to set you up for success at Nexford.',
    },
];

export default function ApplicationProcess() {
    return (
        <div className='bg-[#F5F5F5] p-4 sm:p-6 rounded-b-3xl '>
            <div className='collapse collapse-arrow bg-[#FFFFFF] px-4 sm:px-8 py-5 rounded-lg mb-6'>
                <input type="radio" name="my-accordion-2" defaultChecked />

                <h2 className='collapse-title text-xl font-bold text-[#111927] my-8'>
                    Application Process
                </h2>
                <div className='collapse-content grid sm:grid-cols-2 gap-4'>
                    {cardContents.map((card) => (
                        <div
                            key={card.id}
                            className=' bg-[#F5F5F5] rounded-xl p-4 sm:p-6 w-10/12 m-auto'
                        >
                            <h2 className='font-normal text-right text-6xl text-[#D9D9D9]'>{card.id.toString().padStart(2, '0')}</h2>
                            <h3 className='font-bold text-left text-lg text-[#40A353] my-4'>{card.title}</h3>
                            <p className='font-normal text-left text-sm text-[#111927]'>{card.details}</p>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}
