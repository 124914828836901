import React from 'react';
import styles from './AdminNavbar.module.css'; // Make sure to create and link this CSS file
// import profilePic from '../../assets/user.svg'; // Replace with your actual profile image path
import { Link } from 'react-router-dom';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
function AdminNavbar() {
  const username = localStorage.getItem("first_name");
  return (
    <div className={styles.navbar}>
      <Link className={styles.homeText} to="/"><p >Home</p> </Link>
      <div className={styles.profileSection}>
        {/* <img src={profilePic} alt="Profile" className={styles.profilePic} />
         */}
        <AccountCircleIcon style={{ fontSize: "50px" }} />
        <span className={styles.profileName}>Welcome {username}</span>
      </div>
    </div>
  );
}

export default AdminNavbar;
