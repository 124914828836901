import React, { Suspense, lazy } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import Navbar from "./Components/Navbar/Navbar";
import SubFooter from "./Components/SubFooter/SubFooter";
import "./App.css";
import Students from "./pages/Dashboard/Students/Students";
import Layout from "./pages/Dashboard/Layout";
import AdminNavbar from "./Components/AdminNavbar/AdminNavbar";
import AllPrograms from "./pages/AllPrograms/AllPrograms";
import AllUniversities from "./pages/AllUniversities/AllUniversities";
import ResetPassword from "./pages/ResetPasswordPage/ResetPasswordPage";
import Programs from "./pages/Dashboard/Programs/Programs";
import CreateProgram from "./pages/Dashboard/CreateProgram/CreateProgram";
import ProtectedRoute from "./ProtectedRoute"; // Import the new ProtectedRoute
import ProgramBBA from "./pages/ProgramBBA/ProgramBBA";
import ProgramMBA from "./pages/ProgramMBA/ProgramMBA";
import ProgramMS from "./pages/ProgramMS/ProgramMS";
import PaymentPlans from "./pages/PaymentPlans/PaymentPlans";
import ContinueInstallment from "./pages/PaymentPlans/ContinueInstallment";
import ScrollToTop from "./Components/ScrollToTop";
import ProgramAI from './pages/AIProgram/ProgramAI';
import IntroductionAi from './pages/IntroductionAi/IntroductionAi';
import FawryEgyptianPayment from './Components/FawryEgyptianPayment/FawryEgyptianPayment';

const HomeNew = lazy(() => import("./pages/HomePage/HomePage"));
const Nexford = lazy(() => import("./pages/Nexford/Nexford"));
const TalentAcademy = lazy(() => import("./pages/TalentAcademy/TalentAcademy"));
const Payment = lazy(() => import("./pages/PaymentPlans/FawryCardPayment"));
const ProgramDetails = lazy(() =>
  import("./pages/ProgramDetails/ProgramDetails")
);
const SuccessPayment = lazy(() =>
  import("./pages/Payment/SuccessPayment/SuccessPayment")
);
const Login = lazy(() => import("./pages/Login/Login"));
const Register = lazy(() => import("./pages/Register/Register"));
const FailedPayment = lazy(() => import("./pages/FailedPayment/FailedPayment"));

function AppContent() {
  const location = useLocation();
  const isAdminRoute = location.pathname.startsWith("/admin-dashboard");
  const isAuthRoute =
    location.pathname === "/login" ||
    location.pathname === "/register" ||
    location.pathname === "/success-payment" ||
    location.pathname === "/failed-payment";

  return (
    <>
      {isAdminRoute ? <AdminNavbar /> : !isAuthRoute && <Navbar />}
      <div className="appContainer">
        <Suspense fallback={<div>Loading...</div>}>
          <Routes>
            <Route path="/" element={<HomeNew />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/nexford" element={<Nexford />} />
            <Route path="/talent-academy" element={<TalentAcademy />} />
            <Route path="/payment/:id/:selectedPlan" element={<Payment />} />
            <Route path="/Fawry-payment/:id" element={<FawryEgyptianPayment />} />

            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="/success-payment" element={<SuccessPayment />} />
            <Route path="/failed-payment" element={<FailedPayment />} />

            {/* Protecting admin-dashboard routes */}
            <Route
              path="/admin-dashboard/*"
              element={
                <ProtectedRoute>
                  <Layout />
                </ProtectedRoute>
              }
            >
              <Route path="students" element={<Students />} />
              <Route path="programs" element={<Programs />} />
              <Route path="programs/create" element={<CreateProgram />} />
            </Route>

            <Route path="/all-programs" element={<AllPrograms />} />
            <Route path="/program/:id" element={<ProgramDetails />} />
            <Route path="/all-universities" element={<AllUniversities />} />
            <Route path="/bba-program" element={<ProgramBBA />} />
            <Route path="/ms-program" element={<ProgramMS />} />
            <Route path="/mba-program" element={<ProgramMBA />} />
            <Route path="/ai-program" element={<ProgramAI />} />
            <Route path="/Intr-ai-program" element={<IntroductionAi />} />


            <Route path="/payment-plans/:id" element={<PaymentPlans />} />
            <Route
              path="/continue-installment/:id"
              element={<ContinueInstallment />}
            />
          </Routes>
        </Suspense>
      </div>
      {!isAuthRoute && <SubFooter />}
    </>
  );
}

function App() {
  return (
    <Router>
      <ScrollToTop />
      <AppContent />
    </Router>
  );
}

export default App;
