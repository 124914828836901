import React from "react";
import CardIcon from "../../Components/SVG/CardIcon";
import BankIcon from "../../Components/SVG/BankIcon";
import FawryIcon from "../../Components/SVG/FawryIcon";

const PaymentMethodSelector = ({
  selectedPaymentMethod,
  setSelectedPaymentMethod,
  handlePayNow,
  selectedPlan,
  price,
  fawryAvailable,
  fawryData,
}) => {
  const getContentBasedOnPlan = () => {
    if (selectedPlan === 1) {
      return (
        <>
          <h2 className="font-semibold mb-4 text-center text-[2rem]">Don't Miss</h2>
          <h2 className="text-green-600 font-bold mb-6 text-center text-[2rem]">
            Get 5% off
          </h2>
        </>
      );
    }
    if (selectedPlan === 2) {
      return (
        <div className="text-center text-xl text-gray-600 mb-4">
          <p className="my-8">
            First payment now and the second payment after.
          </p>
          <p className="text-green-500 font-bold">[time period, e.g., 3 months]</p>
        </div>
      );
    }
    if (selectedPlan === 4) {
      return (
        <div className="text-center text-xl text-gray-600 mb-4">
          <p className="my-8">
            First payment now with remaining payments spread over{" "}
          </p>
          <p className="text-green-500 font-bold">[time period, e.g., 3 months]</p>
        </div>
      );
    }
    return null;
  };

  return (
    <div className="w-full lg:w-1/2 p-6 border rounded-lg shadow-md">
      {/* Price Display */}
      <p className="text-2xl text-green-600 text-center font-bold border w-[30%] m-auto rounded p-2">
        {selectedPaymentMethod === "Fawry" && fawryAvailable
          ? `EGP ${fawryData?.final_price || "Loading..."}`
          : `$${price || "Loading..."}`}
      </p>

      {/* Plan Details */}
      {getContentBasedOnPlan()}

      {/* Payment Method Options */}
      <div className="mt-8 mb-3">
        <h3 className="text-lg font-medium mb-5 text-center">Payment methods:</h3>
        <div className="flex gap-4 justify-center">
          {/* Card Payment Option */}
          <label
            className={`flex items-center p-3 rounded-lg cursor-pointer ${
              selectedPaymentMethod === "Card"
                ? "border-2 border-green-500 bg-green-50 text-green-500"
                : "border border-gray-300 text-gray-400"
            }`}
          >
            <input
              type="radio"
              name="paymentMethod"
              value="Card"
              checked={selectedPaymentMethod === "Card"}
              onChange={() => setSelectedPaymentMethod("Card")}
              className="hidden"
            />
            <CardIcon
              className="mr-2 w-[1.5rem] h-[1.5rem]"
              color={selectedPaymentMethod === "Card" ? "#40A353" : "#CDD5E0"}
            />
            Card
          </label>

          {/* Bank Transfer Option */}
          <label
            className={`flex items-center p-3 rounded-lg cursor-pointer ${
              selectedPaymentMethod === "Bank Transfer"
                ? "border-2 border-green-500 bg-green-50 text-green-500"
                : "border border-gray-300 text-gray-400"
            }`}
          >
            <input
              type="radio"
              name="paymentMethod"
              value="Bank Transfer"
              checked={selectedPaymentMethod === "Bank Transfer"}
              onChange={() => setSelectedPaymentMethod("Bank Transfer")}
              className="hidden"
            />
            <BankIcon
              className="mr-2 w-[1.5rem] h-[1.5rem]"
              color={selectedPaymentMethod === "Bank Transfer" ? "#40A353" : "#CDD5E0"}
            />
            Bank Transfer
          </label>

          {fawryAvailable && (
            <label
              className={`flex items-center p-3 rounded-lg cursor-pointer ${
                selectedPaymentMethod === "Fawry"
                  ? "border-2 border-green-500 bg-green-50 text-green-500"
                  : "border border-gray-300 text-gray-400"
              }`}
            >
              <input
                type="radio"
                name="paymentMethod"
                value="Fawry"
                checked={selectedPaymentMethod === "Fawry"}
                onChange={() => setSelectedPaymentMethod("Fawry")}
                className="hidden"
              />
              <FawryIcon
                className="mr-2 w-[1.5rem] h-[1.5rem]"
                color={selectedPaymentMethod === "Fawry" ? "#40A353" : "#CDD5E0"}
              />
              
            </label>
          )}
        </div>
      </div>

      <button
        className="w-[85%] m-auto mt-6 p-3 rounded-lg bg-green-500 text-white text-lg font-semibold hover:bg-green-600 disabled:bg-gray-300 disabled:cursor-not-allowed"
        onClick={handlePayNow}
      >
        Continue
      </button>
    </div>
  );
};

export default PaymentMethodSelector;
