import { useState } from "react";
import { useParams } from "react-router-dom";
import { useFetch } from "../../hooks/useFetch";
import { axiosInterceptor } from "../../Service/axiosInterceptor";
import {
  paymentFawryCardEndPoint,
  paymentPriceEndpoint,
  paymentStatusEndpoint,
} from "../../Service/endpoints";
import FawryPymentDetailsEgyptian from './ FawryPymentDetailsEgyptian';
function FawryEgyptianPayment() {
  const { id } = useParams();

  const [loading, setLoading] = useState(false);
  const [cardError, setCardError] = useState(null);
  const [cardNumber, setCardNumber] = useState("");
  const [cardExpiryYear, setCardExpiryYear] = useState("");
  const [cardExpiryMonth, setCardExpiryMonth] = useState("");
  const [cvv, setCvv] = useState("");

  const { data: fawryData } = useFetch(
    `${paymentPriceEndpoint}?program_id=${id}&installment_plan=${0}&payment_method=fawry`
  );
  const { data: installmentPlansPayment } = useFetch(
    `${paymentStatusEndpoint}${id}`
  );
  
  const handleCardPayment = async () => {
    setLoading(true);
    setCardError(null);

    try {
      const response = await axiosInterceptor.post(paymentFawryCardEndPoint, {
        card_number: cardNumber,
        card_expiry_year: cardExpiryYear,
        card_expiry_month: cardExpiryMonth,
        cvv: cvv,
        program_id: parseInt(id),
        installment_plan: 1,

        total_installments_paid:
          0,
      });

      if (response.data.statusCode === 200) {
        window.location.href = response.data.nextAction.redirectUrl;
      } else {
        setCardError(
          response.data.statusDescription ||
          "An error occurred. Please try again."
        );
      }
    } catch (error) {
      setCardError("An error occurred. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="payment-container flex flex-col lg:flex-row gap-8 px-6 py-10">
      <div className="border rounded-lg p-6 w-[50%]">
        <h2 className="text-2xl font-bold mb-4">Payment</h2>

        <div>
          <label className="block mb-2 text-sm font-medium">
            Card Number
            <input
              type="text"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              placeholder="1234 5678 9101 1121"
              value={cardNumber}
              onChange={(e) => setCardNumber(e.target.value)}
            />
          </label>

          <div className="flex gap-4">
            <label className="block flex-1 mb-2 text-sm font-medium">
              Expiration Month
              <input
                type="text"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                placeholder="MM"
                value={cardExpiryMonth}
                onChange={(e) => setCardExpiryMonth(e.target.value)}
              />
            </label>
            <label className="block flex-1 mb-2 text-sm font-medium">
              Expiration Year
              <input
                type="text"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                placeholder="YY"
                value={cardExpiryYear}
                onChange={(e) => setCardExpiryYear(e.target.value)}
              />
            </label>
          </div>

          <label className="block mb-2 text-sm font-medium">
            CVV
            <input
              type="password"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              placeholder="123"
              value={cvv}
              onChange={(e) => setCvv(e.target.value)}
            />
          </label>

          <button
            className="w-[50%] m-auto bg-green-500 text-white py-2 rounded-lg mt-4"
            onClick={handleCardPayment}
            disabled={loading}
          >
            {loading ? "Processing..." : `Pay Now `}
          </button>
        </div>
        {cardError && <p className="text-red-500 mt-2">{cardError}</p>}
      </div>

      <div className="w-full lg:w-1/2">
        <FawryPymentDetailsEgyptian
          finalPrice={installmentPlansPayment?.program_price}

        />
      </div>
    </div>
  );
}

export default FawryEgyptianPayment;
