import React from "react";
import Calender from "../SVG/Calender";
import styles from "./IntroProgram.module.css";
import GraduationCap from "../SVG/GraduationCap";


export default function IntroProgram({
  logo,
  title,
  duration,
  credits,
  paragraph,
  paragraphClassName,
  studentTypeClassName,
  studentType,

}) {
  return (
    <>
      <div className={`${styles.introProgram} custom-container`}
      >
        <img src={logo} alt="companyLogo" className={styles.companyLogo} />
        {title && <h1>{title}</h1>}
        {paragraph == "AI for a Better Future: Applications, Skills, and Careers for" ? <p className={paragraphClassName}><strong className='text-green-500'> AI</strong> for a Better Future: Applications, Skills, and Careers for</p> : <p className={paragraphClassName}>{paragraph}</p>}
        {studentType && <p className={studentTypeClassName}>{studentType}</p>}
        {duration && <h4>
          <Calender />
          Duration : {duration}
        </h4>}
        {credits && <h4>
          <GraduationCap />
          Credits : {credits}
        </h4>}
      </div>
    </>
  );
}
