import nexfordLogo from "../assets/nextFordLogo.png";
import nextera from "../assets/nextEra.svg";
import nexFordImage from '../assets/exford.png';
import nexeraImage from '../assets/nexera_education_cover 1.png';



export const Programs = [
  {
    id: 11,
    name: "Bachelor of Business Administration (BBA)",
    description:
      "The Bachelor of Business Administration (BBA) at Nexford University is a 100% online, flexible program designed to build global business skills.",
    logo: nexfordLogo,
    pageRoute: "/bba-program",
    rightImage: nexFordImage
  },

  {
    id: 10,
    name: "Master’s of Science (MS)",
    description:
      "The Online Master of Science Degree Programs - US-Accredited at Nexford, you can study fully online MS courses and still earn while doing so. ",
    logo: nexfordLogo,
    pageRoute: "/ms-program",
    rightImage: nexFordImage
  },

  {
    id: 9,
    name: "MBA Program",
    description:
      "Our MBA provides skills to drive your career to new levels How will your CV stand out in an ever more crowded job market? If you’re not thinking about adding an MBA to yours, you should be: applications to online MBA programs increased by over 43% in 2020.",
    logo: nexfordLogo,
    pageRoute: "/mba-program",
    rightImage: nexFordImage
  },

  {
    id: 12,
    name: "AI Program",
    description:
      "AI for a Better Future: Applications, Skills, and Careers for Egyptian Students",
    logo: nextera,
    pageRoute: "/ai-program",
    rightImage: nexeraImage
  },
  {
    id: 13,
    name: "Introduction to AI Literacy",
    description:
      "Equip students with a basic understanding of Artificial Intelligence (AI), its applications, ethical considerations, and how it impacts everyday life. By the end of this training, students will have the foundational knowledge needed to navigate and engage with AI responsibly.",
    logo: nextera,
    pageRoute: "/Intr-ai-program",
    rightImage: nexeraImage
  },
];
