import React, { useState } from 'react';
import IntroProgram from '../../Components/IntroProgram/IntroProgram';
import ProgramPaymentInfo from '../../Components/ProgramPaymentInfo/ProgramPaymentInfo';
import companyLogo from '../../assets/nextEra.svg';
import CourseOverview from './CourseOverview/CourseOverview';
import styles from './ProgramAI.module.css';
import Tabs from './Tabs/Tabs';
import MaterialsNeeded from './MaterialsNeeded/MaterialsNeeded';
import LearningOutcomes from './LearningOutcomes/LearningOutcomes';

export default function ProgramAI() {
  const [activeTab, setActiveTab] = useState('overview');

  const tabs = [
    { key: 'overview', title: 'Course Overview' },
    { key: 'materialsNeeded', title: 'Materials Needed' },
    { key: 'learningOutcomes', title: 'Learning Outcomes' },
  ];

  const renderContent = () => {
    switch (activeTab) {
      case 'overview':
        return <CourseOverview />;
      case 'materialsNeeded':
        return <MaterialsNeeded />;
      case 'learningOutcomes':
        return <LearningOutcomes />;
      default:
        return null;
    }
  };

  return (
    <div className={styles.programMBAContainer}>
      <div className={`sm:flex ${styles.introProgramContainer}`}>
        <IntroProgram
          logo={companyLogo}
          paragraph='AI for a Better Future: Applications, Skills, and Careers for'
          paragraphClassName="text-5xl my-6"
          studentType="Egyption students"
          studentTypeClassName="text-green-500 text-5xl font-bold"
        />
        <ProgramPaymentInfo programId={12} duration='3 Days' />
      </div>

      <div className='custom-container'>
        <div className="flex flex-col sm:flex-row my-6 gap-6">
          {/* Tabs Component */}
          <Tabs activeTab={activeTab} setActiveTab={setActiveTab} tabs={tabs} />

          {/* Content */}
          <div className='sm:w-3/4 sm:p-4'>{renderContent()}</div>
        </div>
      </div>
    </div>
  );
}
