import React from 'react';
import styles from './SubFooter.module.css';
import Footerimage from '../../assets/footerImgae.svg';
import email from '../../assets/gmail logo.svg';
import location from '../../assets/location logo.svg';
import faceBook from '../../assets/facebook_logo.svg';
// import twitter from '../../assets/twitter_logo.svg';
import linkedin from '../../assets/linkedin_logo.svg';
import insta from '../../assets/instagrem_logo.svg';





export default function SubFooter() {
    return (
        <>
            <footer className={styles.subFooter} id="contact-us">
                <div className={styles.inside_footer}>


                    <img src={Footerimage} alt="" srcSet="" />
                    <div className={styles.content}>
                        <div className={styles.divFlex}>
                            <img src={email} alt="" />
                            <div className={styles.SecandDiv}>
                                <p className={styles.SecandDivp1}>Email Support </p>
                                <p className={styles.SecandDivp2}>info@nextera.education</p>
                            </div>

                        </div>
                        <div className={styles.divFlex}>
                            <img src={location} alt="" />
                            <div className={styles.SecandDiv}>
                                <p className={styles.SecandDivp1}>Address </p>
                                <p className={styles.SecandDivp2}>Villa 2 , 212 st Vectoria Sq.Maadi , Cairo</p>
                            </div>

                        </div>

                    </div>
                    <div className={styles.content}>
                        <h1>FOLLOW US</h1>
                        <div className={`flex gap-6 ${styles.followUs}`}>
                            <a href='https://www.facebook.com/share/2ceBkZWQp2pSY6bA/?mibextid=LQQJ4d'><img src={faceBook} alt="faceBook" /> </a>
                            {/* <a href=''><img src={twitter} alt="twitter" /></a> */}
                            <a href='https://www.linkedin.com/company/nexera-education/about/'> <img src={linkedin} alt="linkedin" /></a>
                            <a href='https://www.instagram.com/nexeraeducation?igsh=anNxZG9jZms1NW55'><img src={insta} alt="insta" /></a>
                        </div>

                    </div>
                </div>
                {/* <hr></hr> */}
                <p className={styles.lastFooter}>© Copyright 2024, All Rights Reserved by NEXTERA</p>

            </footer>
        </>
    );
}
