import styles from "./InstallmentPriceCard.module.css";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import OutlinedFlagIcon from "@mui/icons-material/OutlinedFlag";
import React from "react";
import { useNavigate } from 'react-router-dom';

export default function InstallmentPriceCard({
  duration,
  language,
  logo,
  paymentStatus,
  programId,
  hideEnrollButton = false,
}) {
  const navigate = useNavigate();
  return (
    <div className={`${styles.cardPriceContainer} ${hideEnrollButton ? styles.noButton : ""}`}>
      <h4>Installment Fess</h4>
      <h2>{paymentStatus?.program_price} USD</h2>
      {!hideEnrollButton && (
        <button
          onClick={() =>

            navigate(`/continue-installment/${programId}`)
          }
          className="btn-primary"
        >
          Pay Installment
        </button>
      )}
      <div className={styles.divInCard}>
        <AccessTimeIcon />
        <h2>Course Duration</h2>
        <p>{duration}</p>
      </div>
      <div className={styles.divInCard}>
        <OutlinedFlagIcon />
        <h2>Language</h2>

        <p>{language}</p>
      </div>
      {programId === "12" && programId === "13" && <p>
        Certificate accredited by: <img src={logo} alt="companyLogo" />
      </p>}
    </div>
  );
}
