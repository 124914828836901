import { useGetProgramPaymentStatus } from "../../hooks/useGetProgramPaymentStatus";

export const useProgramPaymentInfo = (programId) => {
  const paymentStatus = useGetProgramPaymentStatus(programId);
  const status = paymentStatus?.status;
  const totalInstallmentsPaid = paymentStatus?.total_installments_paid;
  const isProgramPaid = status === "Paid";
  const isEnrollCardVisible =
    status === "Not paid" && totalInstallmentsPaid === 0;
  return { isEnrollCardVisible, paymentStatus, isProgramPaid };
};
