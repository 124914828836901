import React from 'react';
import ArrowRight from '../../../Components/SVG/ArrowRight';

export default function ContentCard({ data }) {
    const { title, description, learningPoints, programCourses } = data;

    // Function to split courses into two columns
    const splitCourses = (courses) => {
        const middleIndex = Math.ceil(courses.length / 2);
        return [courses.slice(0, middleIndex), courses.slice(middleIndex)];
    };

    const [leftColumnCourses, rightColumnCourses] = splitCourses(programCourses);

    return (
        <div className='bg-[#FFFFFF] collapse collapse-arrow px-3 sm:px-8 py-5 rounded-lg mb-6'>
            <input type="radio" name="my-accordion-2" />

            <h2 className='text-xl collapse-title font-bold text-[#111927] my-6'>{title}</h2>
            <div className='collapse-content'>
                <p className='text-lg font-medium text-[#4E5566]'>{description}</p>
                <div className='bg-[#FFFFFF] py-5 rounded-lg mb-6'>
                    <h2 className='text-2xl font-bold text-[#1D2026] my-4'>You will learn:</h2>
                    {learningPoints.map((point, index) => (
                        <span
                            key={index}
                            className='text-[#4E5566] text-base font-semibold grid grid-flow-col gap-2 w-fit mb-2'
                        >
                            <ArrowRight />
                            {point}
                        </span>
                    ))}
                </div>
                <div className='p-3 rounded-lg mb-6 bg-[#FAFAFA]'>
                    <h2 className='text-2xl font-bold text-[#40A353] text-center my-4'>Program Courses</h2>
                    <div className='flex gap-4 justify-around my-6'>
                        <div className='w-2/4'>
                            {leftColumnCourses.map((course, index) => (
                                <span
                                    key={index}
                                    className='text-[#4E5566] text-base font-semibold grid grid-flow-col gap-2 w-fit mb-2'
                                >
                                    <ArrowRight />
                                    {course}
                                </span>
                            ))}
                        </div>
                        <div className='w-2/4'>
                            {rightColumnCourses.map((course, index) => (
                                <span
                                    key={index}
                                    className='text-[#4E5566] text-base font-semibold grid grid-flow-col gap-2 w-fit mb-2'
                                >
                                    <ArrowRight />
                                    {course}
                                </span>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
