import React, { useState } from 'react';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { TextField, IconButton, InputAdornment, Button, Alert } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import styles from './ResetPasswordPage.module.css';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Nextera from '../../assets/nextEra.svg';
import loginNextEra from '../../assets/loginNextEra.svg';

const ResetPasswordSchema = Yup.object().shape({
  new_password: Yup.string().min(6, 'Password too short').required('New password is required'),
  confirm_new_password: Yup.string()
    .oneOf([Yup.ref('new_password'), null], 'Passwords must match')
    .required('Confirm your password'),
});

function ResetPassword() {
  const location = useLocation();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  // Parse UID and Token from the URL query parameters
  const queryParams = new URLSearchParams(location.search);
  const uid = queryParams.get('uid');
  const token = queryParams.get('token');

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleResetPassword = async (values, { setSubmitting }) => {
    setSubmitting(true);
    setErrorMessage('');
    setSuccessMessage('');

    try {
      const response = await axios.post(
        'https://backend.nextera.education/user/password-reset-confirm/',
        {
          uid,
          token,
          new_password: values.new_password,
          confirm_new_password: values.confirm_new_password,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': '3lYbpsbiEPK58VAvxbM5Z8VMEZs5HCCF7tOfMfmO3EJTZgcQ9uZxAOmxq4EaFAlz',
          },
        }
      );

      setSuccessMessage(response.data.message || 'Password has been reset successfully.');
      setTimeout(() => {
        navigate('/login');
      }, 5000); // Redirect to login page after 2 seconds
    } catch (error) {
      if (error.response && error.response.data && error.response.data.detail) {
        setErrorMessage(error.response.data.detail);
      } else {
        setErrorMessage('An unexpected error occurred. Please try again.');
      }
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div className={styles.loginContainer}>
      <div className={styles.formSection}>
        <Link to="/"> <img src={Nextera} width="250px" alt="logo" /></Link>
        <br /><br /><br />
        <h2>Reset Password</h2>
        <p>Enter your new password below.</p>
        <Formik
          initialValues={{ new_password: '', confirm_new_password: '' }}
          validationSchema={ResetPasswordSchema}
          onSubmit={handleResetPassword}
        >
          {({ errors, touched, isSubmitting }) => (
            <Form className={styles.loginForm}>
              <Field
                as={TextField}
                variant="outlined"
                fullWidth
                type={showPassword ? 'text' : 'password'}
                label="New Password"
                name="new_password"
                error={touched.new_password && Boolean(errors.new_password)}
                helperText={touched.new_password && errors.new_password}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={togglePasswordVisibility} edge="end">
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />

              <Field
                as={TextField}
                variant="outlined"
                fullWidth
                type={showConfirmPassword ? 'text' : 'password'}
                label="Confirm New Password"
                name="confirm_new_password"
                error={touched.confirm_new_password && Boolean(errors.confirm_new_password)}
                helperText={touched.confirm_new_password && errors.confirm_new_password}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={toggleConfirmPasswordVisibility} edge="end">
                        {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />

              {errorMessage && (
                <Alert severity="error" style={{ margin: '1rem 0' }}>{errorMessage}</Alert>
              )}
              {successMessage && (
                <Alert severity="success" style={{ margin: '1rem 0' }}>{successMessage}</Alert>
              )}

              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                disabled={isSubmitting}
                className={styles.loginButton}
              >
                Reset Password
                <ArrowForwardIcon />
              </Button>
            </Form>
          )}
        </Formik>
      </div>
      <div className={styles.imageSection}>
        <img className={styles.layerImage} src={loginNextEra} alt="Background" />
      </div>
    </div>
  );
}

export default ResetPassword;
