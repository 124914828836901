import React from 'react'

export default function Calender({ className }) {
    return (
        <svg
            width={26}
            height={27}
            viewBox="0 0 26 27"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <path
                d="M8.58594 2.76807V5.98763"
                stroke="white"
                strokeWidth={1.60978}
                strokeMiterlimit={10}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M17.1719 2.76807V5.98763"
                stroke="white"
                strokeWidth={1.60978}
                strokeMiterlimit={10}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M3.75586 10.3765H22.0001"
                stroke="white"
                strokeWidth={1.60978}
                strokeMiterlimit={10}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M22.5361 9.74387V18.866C22.5361 22.0855 20.9263 24.2319 17.1702 24.2319H8.58469C4.82853 24.2319 3.21875 22.0855 3.21875 18.866V9.74387C3.21875 6.52431 4.82853 4.37793 8.58469 4.37793H17.1702C20.9263 4.37793 22.5361 6.52431 22.5361 9.74387Z"
                stroke="white"
                strokeWidth={1.60978}
                strokeMiterlimit={10}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M16.8432 15.3247H16.8528"
                stroke="white"
                strokeWidth={2.14638}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M16.8432 18.5439H16.8528"
                stroke="white"
                strokeWidth={2.14638}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M12.8725 15.3247H12.8821"
                stroke="white"
                strokeWidth={2.14638}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M12.8725 18.5439H12.8821"
                stroke="white"
                strokeWidth={2.14638}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M8.90179 15.3247H8.91143"
                stroke="white"
                strokeWidth={2.14638}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M8.90179 18.5439H8.91143"
                stroke="white"
                strokeWidth={2.14638}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}
