import React from 'react';
import HeaderCard from './HeaderCard';
import ContentCard from './ContentCard';

export default function CourseOverview() {
    const coursesData = [
        {
            title: 'MSc in Digital Transformation',
            description: `With digital transformation shaping tomorrow's job market, this Master of Science (MSc) in Digital Transformation degree positions you at the epicenter of the tech revolution. An MS in Digital Transformation will help you be a top candidate for senior leadership positions. As a Nexford learner, you get exclusive access to CompTIA's Project+, Network+, and Security+ certification courses. This powerful blend of a degree and preparation for industry-respected certifications will showcase your mastery of IT and readiness to lead tomorrow's technology transformations.`,
            learningPoints: [
                'Lead Collaborative Innovation',
                'Create Scalable Strategies',
                'Tackle Ethical Tech Challenges',
                'Elevate Continuous Growth',
            ],
            programCourses: [
                'Internet of Things',
                'Product Management with Agile & Lean',
                'The Art of Communication',
                'Technology & Operations Management',
                'Artificial intelligence',
                'Cybersecurity leadership',
                'Introduction to Intrapreneurship and Innovation',
                'Robotics & Automation',
                'Data Science for Decision Making',
                'Enabling E-commerce & Digital Strategy',
                'Corporate Sustainability',
                'Digital Transformation Capstone',
            ],
        },
        {
            title: 'MSc in Data Analytics',
            description: `The ability to analyze data and make strategic decisions is critical to success in the modern workplace. The MSc in Data Analytics from Nexford empowers you with the technical and business skills needed to lead organizations and unlock the true potential of data. General Education: Courses like Intercultural Communication and Business Statistics to build foundational skills. Our MS in Data Analytics program not only equips you with a cutting-edge degree but also grants you exclusive access to the highly sought-after CompTIA Data+ certification. This industry-recognized credential validates your data expertise, making you a more competitive candidate and opening doors to exciting career opportunities. By graduating with both a master's degree and the CompTIA Data+ certification, you'll showcase a comprehensive skill set that sets you apart in the data-driven job market.`,
            learningPoints: [
                'Big Data',
                'Modern Tools',
                'Machine Learning',
                'Data Visualization',
            ],
            programCourses: [
                'Programming in Python & R',
                'Information Visualization & Communication',
                'Data Sciences for Decision Makers',
                'Corporate Sustainability',
                'Data Modeling & Mining',
                'The Law & Ethics of Information Technology',
                'Statistics for Business Analytics',
                'Business Analytics',
                'Applied Machine Learning for Business Analytics',
                'The Art of Communication',
                'Accounting & Financial Reporting',
                'Business Analytics Capstone',
            ],
        },
        {
            title: 'MSc in Entrepreneurship',
            description: `Building a startup is more than just having an idea. Our program will help you maximize your success as a startup founder. You'll get the tools, skills, and networking to thrive. Gain lifelong relationships, essential knowledge, and strategies to drive your startup’s growth and success. We help founders throughout their entrepreneurial journey. When you join our community, you’re joining a network of like-minded founders with a common entrepreneurial interest. Move your startup from aspiration to action! If you are currently a startup founder or looking to become one within the next 12 months, this program was built for you. As an aspiring or recent startup founder, you'll get the necessary skills and support to set your business up for success. Going solo may seem the easiest path, but the truth is that having a peer group and someone to hold you accountable while learning and building will provide the fuel you need to accelerate your business.`,
            learningPoints: [
                'Startup Strategy & Development',
                'Product Design & Development',
                'Funding for Startup Founders',
            ],
            programCourses: [
                'From Idea to Pitch',
                'Startup Strategy and Development',
                'Mastering Personal Marketing & Sales Skills',
                'The Art of Communication',
                'Marketing Strategy',
                'Entrepreneurial Mindset Seminar',
                'Product Go-to Market',
                'Entrepreneurship Capstone',
                'Essential Power Skills for Leaders & Entrepreneurs',
                'Conflict Management and Negotiation Seminar',
                'Executing a Vision: Product Design & Development for Entrepreneurs',
                'Funding for Startup Founders',
                'Accounting and Financial Reporting',
                'Building and Scaling an Organization',
                'Legal Topics for Founders: Navigating the Corporate Landscape',
            ],
        },
    ];

    return (
        <div className='bg-[#F5F5F5] p-3 sm:p-6 rounded-b-3xl'>
            <HeaderCard />
            {coursesData.map((course, index) => (
                <ContentCard key={index} data={course} />
            ))}
        </div>
    );
}
