import React, { useState } from 'react';
import IntroProgram from '../../Components/IntroProgram/IntroProgram';
import ProgramPaymentInfo from '../../Components/ProgramPaymentInfo/ProgramPaymentInfo';
import companyLogo from '../../assets/nexfordLogo.png';
import AdmissionRequirements from './AdmissionRequirements/AdmissionRequirements';
import ApplicationProcess from '../../Components/ApplicationProcess/ApplicationProcess';
import CourseOverview from './CourseOverview/CourseOverview';
import styles from './ProgramMBA.module.css';
import Specializations from './Specializations/Specializations';
import Tabs from './Tabs/Tabs';
import backgroundImage from '../../assets/IntroBG.png';

export default function ProgramMBA() {
  const [activeTab, setActiveTab] = useState('overview');

  const tabs = [
    { key: 'overview', title: 'Course Overview' },
    { key: 'admission', title: 'Admission Requirements' },
    { key: 'specializations', title: 'Specializations' },
    { key: 'application', title: 'Application Process' },
  ];

  const renderContent = () => {
    switch (activeTab) {
      case 'overview':
        return <CourseOverview />;
      case 'admission':
        return <AdmissionRequirements />;
      case 'specializations':
        return <Specializations />;
      case 'application':
        return <ApplicationProcess />;
      default:
        return null;
    }
  };

  return (
    <div className={styles.programMBAContainer}>
      <div className={`sm:flex ${styles.introProgramContainer}`}>
        <IntroProgram
          logo={companyLogo}
          title='MBA Program'
          duration='18-27 months'
          credits='9 core courses, 3 specialized courses, 3 electives.'
          backgroundImage={backgroundImage}

        />
        <ProgramPaymentInfo programId={9} duration='18-27 months' />
      </div>

      <div className='custom-container'>
        <div className="flex flex-col sm:flex-row my-6 gap-6">
          {/* Tabs Component */}
          <Tabs activeTab={activeTab} setActiveTab={setActiveTab} tabs={tabs} />

          {/* Content */}
          <div className='sm:w-3/4 sm:p-4'>{renderContent()}</div>
        </div>
      </div>
    </div>
  );
}
