import React from "react";
import { useProgramPaymentInfo } from "./useProgramPaymentInfo";
import CardPrice from "../CardPrice/CardPrice";
import nextFordLogo from "../../assets/nextFordLogo.png";
import InstallmentPriceCard from "../InstallmentPriceCard/InstallmentPriceCard";

const ProgramPaymentInfo = ({ programId, duration }) => {
  const { isEnrollCardVisible, paymentStatus, isProgramPaid } =
    useProgramPaymentInfo(programId);
  if (isProgramPaid) return null;

  if (isEnrollCardVisible)
    return (
      <CardPrice
        programId={programId}
        price={paymentStatus?.program_price}
        duration={duration}
        language={"English"}
        logo={nextFordLogo}
        hideEnrollButton={programId === 9 || programId === 10 || programId === 11}
      />
    );
  return (
    <InstallmentPriceCard
      paymentStatus={paymentStatus}
      duration={duration}
      language={"English"}
      logo={nextFordLogo}
      programId={programId}
      hideEnrollButton={programId === 9 || programId === 10 || programId === 11}
    />
  );
};

export default ProgramPaymentInfo;
