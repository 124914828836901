import { axiosInterceptor } from "./axiosInterceptor";

export const apiPostPayWithStripe = async (
  programId,
  installmentPlan,
  totalInstallmentPaid
) => {
  return axiosInterceptor.post("payment-stripe/", {
    program_id: programId,
    installment_plan: installmentPlan,
    total_installments_paid: totalInstallmentPaid,
  });
};
