import React from "react";
import styles from "./AllPrograms.module.css";

import { Programs } from "../../constants";
import Program from "./Program/Program";

export default function AllPrograms() {
  return (
    <div className={styles.allProgramsContainer}>
      <h2>Programs</h2>
      <div className={styles.contentContainer}>
        {Programs.map((program, index) => (
          <Program program={program} key={`program-${index}`} />
        ))}
      </div>
    </div>
  );
}
