import React, { useState } from "react";
import arrowImage from "../../../assets/ArrowRight.png";

export default function CourseOverview() {
  const [activeIndex, setActiveIndex] = useState(null);

  const courseData = [
    {
      day: "Session 1",
      title: "Introduction to AI",
      totalTime: "30 minutes",
      goal: "Familiarize students with what AI is, its history, and its role in society.",
      topics: [
        "Definition of AI: Understanding AI as a technology that enables machines to mimic human tasks.",
        "Brief History of AI: Key milestones in AI development and how it evolved to present-day technology.",
        "Types of AI: Explanation of narrow AI (e.g., voice assistants like Siri) vs. general AI (future potential).",
        "Interactive Discussion: Ask students what AI tools they may have used or encountered (e.g., facial recognition, recommendation algorithms).",
      ],
      activities: [
        "Activity: AI Around Us - Students discuss examples of AI they use in daily life, such as social media, smartphones, or online shopping.",
      ],
    },
    {
      day: "Session 2",
      title: "Real-World Applications of AI",
      totalTime: "25 minutes",
      goal: "Demonstrate how AI is applied across various sectors.",
      topics: [
        "AI in Healthcare: Medical imaging, patient diagnosis.",
        "AI in Education: Adaptive learning platforms, language translation.",
        "AI in Business: Automation in customer service, supply chain management.",
        "AI in Entertainment: Recommendation systems in Netflix, YouTube, and Spotify.",
      ],
      activities: [
        "Interactive Video Quiz - Show a short video illustrating AI in various fields, followed by a quick quiz to reinforce learning.",
      ],
    },
    {
      day: "Session 3",
      title: "Introduction to Machine Learning",
      totalTime: "20 minutes",
      goal: "Provide a basic understanding of machine learning as a subset of AI.",
      topics: [
        "What is Machine Learning (ML)?: Explanation of how ML enables systems to learn from data.",
        "Types of ML: Supervised, unsupervised, and reinforcement learning.",
        "Examples: Facial recognition, voice assistants, and spam filters.",
      ],
      activities: [
        "Hands-On Mini-Demo – Use a simple tool like Teachable Machine (by Google) to show how a machine can learn to recognize images or sounds.",
      ],
    },
    {
      day: "Session 4",
      title: "Ethical and Social Implications of AI",
      totalTime: "25 minutes",
      goal: "Discuss the importance of ethics in AI and responsible use.",
      topics: [
        "Bias in AI: How biased data can lead to unfair outcomes.",
        "Privacy Concerns: Impact of AI on personal privacy.",
        "Job Automation: The effect of AI on future job markets.",
      ],
      activities: [
        "Group Discussion: 'Is AI always beneficial?' Encourage students to think critically about the pros and cons of AI.",
        "Scenario Debate - Present scenarios (e.g., using AI in hiring decisions) and have students discuss potential benefits and drawbacks.",
      ],
    },
    {
      day: "Session 5",
      title: "The Future of AI and Career Paths",
      totalTime: "20 minutes",
      goal: "Inspire students to consider AI-related career opportunities.",
      topics: [
        "AI Trends: Future of AI in robotics, climate change, space exploration.",
        "Careers in AI: Introduction to roles like data scientist, ML engineer, AI ethicist, etc.",
        "Resources for Further Learning: Share free online resources and courses on AI.",
      ],
      activities: [
        "Goal Mapping - Students brainstorm areas where they might want to learn more about AI or potential future career interests.",
      ],
    },
    {
      day: "Session 6",
      title: "Q&A and Wrap-Up",
      totalTime: "10 minutes",
      goal: "Address any remaining questions and review key takeaways.",
      activities: [
        "AI Literacy Certificate - Reward students with a certificate of participation to reinforce the importance of AI literacy.",
      ],
    },
  ];

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="bg-[#F5F5F5] p-6 rounded-lg shadow-lg">
      <div>
        {courseData.map((course, index) => (
          <div
            key={index}
            className="bg-white mb-4 rounded-lg shadow-md"
          >
            <div
              className="flex justify-between items-center px-6 py-4 cursor-pointer"
              onClick={() => toggleAccordion(index)}
            >
              <div className="flex justify-between w-full">
                <h3 className="text-lg font-bold text-[#111927]">
                  {course.day}: {course.title}
                </h3>
                <span className="text-[#40A353] font-medium  mr-2 ml-3">
                  <strong className="text-[#4E5566] mr-2 ml-3">Total Time: </strong>
                  {course.totalTime}
                </span>
              </div>
              <div
                className={`transition-transform duration-300 ${
                  activeIndex === index ? "rotate-180" : ""
                }`}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6 text-[#111927]"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
              </div>
            </div>
            {activeIndex === index && (
              <div className="px-6 py-4">
                <p className="text-lg font-bold mb-4">Goal:</p>
                <p className="text-base text-[#4E5566] mb-4">{course.goal}</p>
                <h4 className="text-lg font-bold mb-2">Topics Covered:</h4>
                <ul className="pl-6 mb-4">
                  {course?.topics?.map((topic, i) => (
                    <div className="flex my-3 items-start" key={i}>
                      <img className="mr-4" src={arrowImage} alt="" />
                      <li className="text-base text-[#4E5566]">{topic}</li>
                    </div>
                  ))}
                </ul>
                <h4 className="text-lg font-bold mb-2">Activities:</h4>
                <ul className="pl-6">
                  {course?.activities?.map((activity, i) => (
                    <div className="flex my-3 items-start" key={i}>
                      <img className="mr-4" src={arrowImage} alt="" />
                      <li className="text-base text-[#4E5566]">{activity}</li>
                    </div>
                  ))}
                </ul>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}
