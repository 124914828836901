import React from 'react';
import Table from '../../../Components/Table/Table';
import ArrowRight from '../../../Components/SVG/ArrowRight';

const tableHeaderClasses =
    'border border-[#FFFFFF] bg-[#40A353] text-[#FFFFFF] text-xl font-bold p-4';
const tableCellClasses =
    'border-4 border-[#FFFFFF] bg-[#F3F3F3] text-[#0E1726] text-center text-base font-medium p-4';
const tableClasses =
    'table-auto border-collapse border border-[#FFFFFF] w-full';

// Data for the tables
const headers = [
    { title: 'English Proficiency Qualification', colSpan: 1 },
    { title: 'Undergraduates', colSpan: 1 },
    { title: 'Graduates', colSpan: 1 },
];

const rows = [
    { cells: ['Minimum Score Required'], colSpan: [3] },
    {
        cells: [
            'Test of English as a Foreign Language (TOEFL) Paper-based Test (PBT)',
            '57',
            '60',
        ],
        colSpan: [1, 1, 1],
    },
    { cells: ['TOEFL Internet-based Test (iBT)', '61', '71'], colSpan: [1, 1, 1] },
    {
        cells: ['International English Language Testing System (IELTS)', '6', '6.5'],
        colSpan: [1, 1, 1],
    },
    {
        cells: ['Pearson Test of English (PTE) Academic Test', '44', '50'],
        colSpan: [1, 1, 1],
    },
    { cells: ['Eiken English Proficiency Exam', 'Pre-1', 'Pre-1'], colSpan: [1, 1, 1] },
    { cells: ['Duolingo English Test', '95', '100'], colSpan: [1, 1, 1] },
    {
        cells: [
            'Exams identified within the Common European Framework of Reference (CEFR)',
            'B-2',
            'B-2',
        ],
        colSpan: [1, 1, 1],
    },
];

export default function AdmissionRequirements() {
    return (
        <div className='bg-[#F5F5F5] p-3 sm:p-6 rounded-b-3xl '>
            <div className='collapse collapse-arrow bg-[#FFFFFF] px-3 sm:px-8 py-5 rounded-lg mb-6'>
                <input type="radio" name="my-accordion-2" defaultChecked />
                <h2 className='collapse-title text-xl font-bold text-[#1D2026] my-4'>
                    Admission Requirements
                </h2>
                <div className='collapse-content'>
                    <span className='text-[#0E1726] text-base font-semibold grid grid-flow-col gap-2 w-fit mb-2'>
                        <ArrowRight />A photo or scan of a government-issued form of
                        identification and a passport-style photo or selfie.
                    </span>
                    <span className='text-[#0E1726] text-base font-semibold grid grid-flow-col gap-2 w-fit mb-2'>
                        <ArrowRight />
                        Proof of graduation :
                    </span>
                    <div className='flex flex-wrap sm:flex-nowrap gap-4 my-2'>
                        <div className='bg-[#FAFAFA] w-auto sm:w-3/6 rounded-lg py-5 my-4 px-4'>
                            <h3 className='text-base font-bold text-[#40A353] mb-4'>
                                Undergraduates
                            </h3>
                            <span className='text-[#111927] text-sm'>
                                Provide proof of high school or college graduation, or a
                                GED/equivalent exam (e.g., HiSET, TASC) with valid copies of your
                                diploma and transcript in English. If your documents aren’t in
                                English, our team will handle the translation for you!
                            </span>
                        </div>
                        <div className='bg-[#FAFAFA] w-auto sm:w-3/6 rounded-lg py-5 my-4 px-4'>
                            <h3 className='text-base font-bold text-[#40A353] mb-4'>
                                Graduates
                            </h3>
                            <span className='text-[#111927] text-sm'>
                                Provide proof of a completed bachelor’s degree from an accredited
                                institution with an official diploma and transcript in English. If
                                not in English, our team will handle the translation. We may
                                request certification that your degree meets our standards and
                                refer you to an external agency for credit evaluation.
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            <div className='collapse collapse-arrow bg-[#FFFFFF] px-4 sm:px-8 py-5 rounded-lg'>
                <input type="radio" name="my-accordion-2" />
                <h2 className='text-xl font-semibold text-[#1D2026] mt-4 mb-8 collapse-title'>
                    English Proficiency
                </h2>
                <div className='collapse-content'>
                    <Table
                        headers={headers}
                        rows={rows}
                        tableClasses={tableClasses}
                        tableHeaderClasses={tableHeaderClasses}
                        tableCellClasses={tableCellClasses}
                    />
                </div>
            </div>
        </div>
    );
}
