import axios from "axios";
import { API_URL } from "./constants";

const myHeaders = {
    "accept": "application/json",
    "Content-Type": "application/json",
    "X-CSRFToken": "fGLWCgOLLLCEon5yDxhQxTerBvEluhG03I79NKUsXyRtNtRxjRZ2F5R66eDaLRor"
};

// API call to register a new user
export const registerUser = async (data) => {
    try {
        const response = await axios.post(`${API_URL}/user/student-register/`, data, {
            headers: myHeaders,
        });
        return response.data;
    } catch (error) {
        console.error("Error in registerUser:", error);
        throw error;
    }
};

// API call to get list status
// export const getListStatus = async () => {
//     try {
//         const response = await axios.get(`${API_URL}/list-status/`, {
//             headers: myHeaders,
//         });
//         return response.data;
//     } catch (error) {
//         console.error("Error in getListStatus:", error);
//         throw error;
//     }
// };

// API call to fetch universities by country
export const getUniversitiesByCountry = async (country) => {
    try {
        const response = await axios.get(`${API_URL}/universities/?country=${country}`);
        // return response.data;
        return response.data.universities;
    } catch (error) {
        console.error("Error in getUniversitiesByCountry:", error);
        throw error;
    }
};

// API call to fetch Country list
export const getCountries = async () => {
    try {
        const response = await axios.get("https://restcountries.com/v3.1/all");
        return response.data.map(country => ({
            name: country.name.common,
            code: country.cca2,
        }));
    } catch (error) {
        console.error("Error fetching countries:", error);
        throw error;
    }
};

// API call to get list of programs for admin dashboard
export const getPrograms = async () => {
    try {
        const response = await axios.get(`${API_URL}/program/`);
        return response.data;
    } catch (error) {
        console.error("Error fetching programs:", error);
        throw error;
    }
}

// API call to get list of programs for admin dashboard
export const createProgram = async (programData) => {
    try {
        const token = localStorage.getItem('accessToken');
        if (!token) {
            throw new Error("No authentication token found. Please log in.");
        }
        const headers = {
            'Authorization': `jwt ${token}`,
            'Content-Type': 'multipart/form-data',
        };
        const response = await axios.post(`${API_URL}/program/`, programData, { headers });
        return response.data;
    } catch (error) {
        console.error('Error creating program:', error);
        throw error;
    }
};
