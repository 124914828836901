import React from 'react';
import arrow from '../../../assets/arrow-down.svg';

export default function Tabs({ activeTab, setActiveTab, tabs }) {
    const handleTabChange = (e) => {
        setActiveTab(e.target.value);
    };

    return (
        <>
            {/* For larger screens */}
            <div className='hidden sm:block w-[22%] h-fit border-2 border-[#40A353] rounded-[1.25rem] px-4 md:px-8 py-10 sticky top-24'>
                <ul className='space-y-5'>
                    {tabs.map((tab) => (
                        <li
                            key={tab.key}
                            className={`cursor-pointer text-center px-4 py-3 text-[#1D2026] font-semibold text-base border rounded-full md:px-4 md:text-base sm:px-2 sm:text-sm ${activeTab === tab.key
                                ? 'border-2 border-[#40A353] font-bold'
                                : 'hover:border-[#40A353]'
                                }`}
                            onClick={() => setActiveTab(tab.key)}
                        >
                            {tab.title}
                        </li>
                    ))}
                </ul>
            </div>

            {/* For mobile screens */}
            <div className='sm:hidden w-full mb-6'>
                <select
                    value={activeTab}
                    onChange={handleTabChange}
                    className='w-full border-2 border-[#40A353] rounded-[1.25rem] px-4 py-3 text-[#1D2026] font-semibold text-xl appearance-none focus:border-gray-7e bg-no-repeat bg-[95%_center] bg-[length:0.9rem] cursor-pointer'
                    style={{ backgroundImage: `url(${arrow})` }}
                >
                    {tabs.map((tab) => (
                        <option key={tab.key} value={tab.key}>
                            {tab.title}
                        </option>
                    ))}
                </select>
            </div>
        </>
    );
}
