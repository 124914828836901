import React from 'react';

function CardIcon({ className, color = "currentColor" }) {
  return (
    <svg
      className={className}
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_810_839)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.9861 4.72922H0.320312V3.90114C0.320312 3.1393 0.814955 2.521 1.42443 2.521H16.882C17.4915 2.521 17.9861 3.1393 17.9861 3.90114V4.72922ZM17.9861 7.48951V14.6662C17.9861 14.9591 17.8698 15.2399 17.6627 15.447C17.4557 15.654 17.1748 15.7704 16.882 15.7704H1.42443C1.1316 15.7704 0.850761 15.654 0.6437 15.447C0.436638 15.2399 0.320313 14.9591 0.320312 14.6662V7.48951H17.9861ZM4.73677 11.3539C4.44394 11.3539 4.1631 11.4702 3.95604 11.6773C3.74898 11.8844 3.63265 12.1652 3.63265 12.458C3.63265 12.7508 3.74898 13.0317 3.95604 13.2387C4.1631 13.4458 4.44394 13.5621 4.73677 13.5621H5.84088C6.13371 13.5621 6.41455 13.4458 6.62161 13.2387C6.82867 13.0317 6.94499 12.7508 6.94499 12.458C6.94499 12.1652 6.82867 11.8844 6.62161 11.6773C6.41455 11.4702 6.13371 11.3539 5.84088 11.3539H4.73677Z"
          fill={color} // Dynamic color
        />
      </g>
      <defs>
        <clipPath id="clip0_810_839">
          <rect
            width="17.6658"
            height="17.6658"
            fill="white"
            transform="translate(0.320312 0.312988)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default CardIcon;
