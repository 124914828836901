import React from 'react';

export default function HeaderCard() {
    return (
        <div className='bg-[#FFFFFF] collapse collapse-arrow px-3 sm:px-8 py-5 rounded-lg mb-6'>
            <input type="radio" name="my-accordion-2" defaultChecked />

            <h2 className='text-xl collapse-title font-bold text-[#111927] my-6'>
                Explore key topics and benefits
            </h2>
            <p className='text-lg collapse-content font-medium text-[#4E5566]'>
                The Online Master of Science Degree Programs - US-Accredited at Nexford,
                you can study fully online MS courses and still earn while doing so. And
                since it's done 100% online, you can learn from any location. Then, when
                done, demonstrate what you can do, not what you’ve read about. Workforce
                data tells us what employers want – spend your MS degree online
                mastering precisely that with a curriculum developed by trusted experts:
                a combination of global industry specialists, faculty, and professional
                business organizations.
            </p>
        </div>
    );
}
