import React from 'react';
import ArrowRight from '../../../Components/SVG/ArrowRight';

const coreSkills = [
    'Financial Accounting',
    'Strategic Management',
    'Marketing Management',
    'Human Resource Management',
    'Business Law',
    'Operations Management',
];

const specialSkills = [
    'Artificial Intelligence (AI) in Business',
    'Digital Marketing',
    'Business Analytics',
    'E-Commerce and Innovation',
];

export default function Outcomes() {
    return (
        <div className='bg-[#F5F5F5] p-3 sm:p-6 rounded-b-3xl '>
            <div className='collapse collapse-arrow bg-[#FFFFFF] px-3 sm:px-6 py-3 rounded-lg mb-6'>
                <input type="radio" name="my-accordion-2" defaultChecked />
                <h2 className='collapse-title text-xl font-bold text-[#111927] my-6'>
                    Career Outcomes
                </h2>
                <div className='collapse-content'>
                    <span className='text-[#4E5566] text-base font-semibold'>
                        Graduates are well-prepared for roles in business management,
                        entrepreneurship, marketing, human resources, and more. With skills in
                        leadership, data analysis, and innovative problem-solving, learners
                        can apply their competencies globally, improving their career
                        prospects in industries like finance, tech, and retail.
                    </span>
                    <div className='flex flex-wrap sm:flex-nowrap gap-4 justify-between my-6'>
                        <div className='bg-[#FAFAFA] mx-auto w-4/5 sm:w-1/2 h-fit rounded-lg py-5 px-3'>
                            <h2 className='text-base font-semibold text-[#40A353] ml-5 mb-4'>
                                Core Business Competencies
                            </h2>
                            {coreSkills.map((skills, index) => (
                                <span
                                    key={index}
                                    className='text-[#111927] text-sm font-normal grid grid-flow-col gap-2 w-fit mb-2'
                                >
                                    <ArrowRight />
                                    {skills}
                                </span>
                            ))}
                        </div>
                        <div className='bg-[#FAFAFA] sm:w-1/2 w-4/5 mx-auto h-fit rounded-lg py-5 px-3'>
                            <h2 className='text-base font-semibold text-[#40A353] ml-5 mb-4'>
                                Core Business Competencies
                            </h2>
                            {specialSkills.map((skills, index) => (
                                <span
                                    key={index}
                                    className='text-[#111927] text-sm font-normal grid grid-flow-col gap-2 w-fit mb-2'
                                >
                                    <ArrowRight />
                                    {skills}
                                </span>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
