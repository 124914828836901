import React from "react";

const BankTransferPopup = ({ open, onClose }) => {
  if (!open) return null;

  return (
    <div
      className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
      onClick={onClose}
    >
      <div
        className="bg-white   w-full max-w-2xl rounded-lg shadow-lg p-6 relative"
        onClick={(e) => e.stopPropagation()}
      >
        <button
          className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
          onClick={onClose}
        >
          ✕
        </button>

        <h2 className="text-xl font-semibold text-center mb-6">
          Bank Transfer Details
        </h2>

        <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
          <div className=" border border-gray-200 p-4 rounded-lg  shadow-sm">
            <h3 className="text-lg text-[#40A353] font-semibold text-center mb-4">EGP</h3>
            <ul className="space-y-2 text-sm text-gray-700">
              <li>
                <b>Bank:</b> CIB
              </li>
              <li>
                <b>Account Number:</b> 100028622327
              </li>
              <li>
                <b>IBAN:</b> EG320010005900000100028622327
              </li>
              <li>
                <b>SWIFT Code:</b> CIBEEGCX059
              </li>
            </ul>
          </div>

          <div className=" border border-gray-200 p-4 rounded-lg shadow-sm">
            <h3 className="text-lg text-[#40A353] font-semibold text-center mb-4">USD</h3>
            <ul className="space-y-2 text-sm text-gray-700">
              <li>
                <b>Bank:</b> CIB
              </li>
              <li>
                <b>Account Number:</b> 100028622389
              </li>
              <li>
                <b>IBAN:</b> EG070010005900000100028622389
              </li>
              <li>
                <b>Account Type:</b> Current Account
              </li>
              <li>
                <b>Bank Address 1:</b> Cairo
              </li>
              <li>
                <b>Bank Address 2:</b> Smart Village
              </li>
              <li>
                <b>Country Code:</b> EG
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BankTransferPopup;
