import React from 'react';
import university1 from '../../assets/universityImage1.jpg';
import university2 from '../../assets/universityImage2.jpg';
import programLogo1 from '../../assets/programslogo1.svg';
import programLogo2 from '../../assets/nexfordlogo.svg';
import rightVector from '../../assets/rightVector.svg';
import { Link } from 'react-router-dom';
import styles from './AllUniversities.module.css';

export default function AllUniversities() {
    const universityData = [
        {
            id: 1,
            description:
                'Nexford University’s mission is to enable greater social and economic mobility across the world by providing learners access to high-quality, affordable, dynamic online education that prepares them for the global workplace.',
            logo: programLogo1,
            image: university1,
            link: '/talent-academy',
            badge: "Founded 6 years ago",
            info: "100 satisfied customers",
            projects: "100 completed projects",
        },
        {
            id: 2,
            description:
                'Nexford University’s mission is to enable greater social and economic mobility across the world by providing learners access to high-quality, affordable, dynamic online education that prepares them for the global workplace.',
            logo: programLogo2,
            image: university2,
            link: '/nexford',
            badge: "Founded 4 years ago",
            info: "90 satisfied customers",
            projects: "80 completed projects",
        },
    ];

    return (
        <div className={styles.allUniversitiesContainer}>
            <h2 className={styles.heading}>University</h2>
            {universityData.map((university) => (
                <div key={university.id} className={styles.universityCard}>
                    <div className={styles.uniImageContainer}>
                        <img src={university.image} alt="university" className={styles.universityImage} />
                        <div className={styles.overlay}>
                            <span className={styles.badge}>{university.badge}</span>
                            <span className={styles.projectsBadge}>{university.projects}</span>
                            <span className={styles.infoBadge}>{university.info}</span>
                        </div>
                    </div>
                    <div className={styles.universityContent}>
                        <h2>University</h2>
                        <img src={university.logo} alt="program logo" className={styles.universityLogo} />
                        <p>{university.description}</p>
                        <Link to={university.link} className={styles.viewMoreBtn}>
                            View More <img src={rightVector} alt="right-vector" />
                        </Link>
                    </div>
                </div>
            ))}
        </div>
    );
}
