import { payWithStripe } from "../../helpers/payWithStripe";
import { useFetch } from "../../hooks/useFetch";
import { paymentPriceEndpoint } from "../../Service/endpoints";

const PaymentPlan = ({ plan, programPrice, programId, selectedPlan, onSelect }) => {
  const { data: paymentPrice } = useFetch(
    `${paymentPriceEndpoint}?program_id=${programId}&installment_plan=${plan.installmentPlan}&payment_method=stripe`
  );

  return (
    <label
      className={`flex p-6 mb-4 border rounded-lg cursor-pointer ${selectedPlan === plan.installmentPlan
        ? "border-green-500 bg-green-50"
        : "border-gray-300"
        }`}
    >
      <input
        type="radio"
        name="paymentPlan"
        value={plan.installmentPlan}
        checked={selectedPlan === plan.installmentPlan}
        onChange={() => onSelect(plan.installmentPlan)}
        className="hidden"
      />
      <div
        className='w-[1.5rem] h-[1.5rem] border rounded-[50%] flex justify-center items-center mr-[.8rem]'
        style={{
          border: selectedPlan === plan.installmentPlan ? "2px solid #40A353" : "2px solid #CDD5E0",
        }}
      >
        {selectedPlan === plan.installmentPlan && (
          <div
            className='w-[.6rem] h-[.6rem] rounded-[50%] bg-[#40A353]'

          ></div>
        )}
      </div>
      <div>
        <h2 className="text-lg font-medium">{plan.title}</h2>
        <p className="text-sm text-gray-600">
          {plan.installmentPlan === 1
            ? "Make a single payment to complete the transaction."
            : `Divide the payment into ${plan.installmentPlan} equal installments.`}
        </p>
      </div>
      {plan.installmentPlan === 1 && (
        <span className="text-2xl text-green-600 text-center ml-5">
          <strong className="text-green-600">5%</strong> <br />
          Discount
        </span>
      )}
    </label>

  );
};

export default PaymentPlan;
