import React, { useState } from "react";
import styles from "./ProgramMS.module.css";
import companyLogo from "../../assets/nexfordLogo.png";
import IntroProgram from "../../Components/IntroProgram/IntroProgram";
import ProgramPaymentInfo from "../../Components/ProgramPaymentInfo/ProgramPaymentInfo";
import Tabs from "../ProgramMBA/Tabs/Tabs";
import AdmissionRequirements from "../ProgramMBA/AdmissionRequirements/AdmissionRequirements";
import ApplicationProcess from "../../Components/ApplicationProcess/ApplicationProcess";
import CourseOverview from "./CourseOverview/CourseOverview";
import backgroundImage from '../../assets/IntroBG.png';

export default function ProgramMS() {
  const [activeTab, setActiveTab] = useState('overview');

  const tabs = [
    { key: 'overview', title: 'Course Overview' },
    { key: 'admission', title: 'Admission Requirements' },
    { key: 'application', title: 'Application Process' },
  ];

  const renderContent = () => {
    switch (activeTab) {
      case 'overview':
        return <CourseOverview />;
      case 'admission':
        return <AdmissionRequirements />;
      case 'application':
        return <ApplicationProcess />;
      default:
        return null;
    }
  };
  return (
    <div className={styles.programMSContainer}>
      <div className={`sm:flex ${styles.introProgramContainer}`}>
        <IntroProgram
          logo={companyLogo}
          title="Master’s of Science (MS)"
          duration="18 months (10-15 hours per week)"
          credits="12 Courses - 8 weeks/course."
          backgroundImage={backgroundImage}

        />
        <ProgramPaymentInfo programId={10} duration='18 months' />
      </div>
      <div className='custom-container'>
        <div className="flex flex-col sm:flex-row my-6 gap-6">
          {/* Tabs Component */}
          <Tabs activeTab={activeTab} setActiveTab={setActiveTab} tabs={tabs} />

          {/* Content */}
          <div className='sm:w-3/4 sm:p-4'>{renderContent()}</div>
        </div>
      </div>
    </div>
  );
}
