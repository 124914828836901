import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useFetch } from "../../hooks/useFetch";
import { paymentPriceEndpoint, paymentStatusEndpoint } from "../../Service/endpoints";
import CardIcon from "../../Components/SVG/CardIcon";
import BankIcon from "../../Components/SVG/BankIcon";
import FawryIcon from "../../Components/SVG/FawryIcon";
import { payWithStripe } from "../../helpers/payWithStripe";
import BankTransferPopup from './BankTransferPopup';

function ContinueInstallment() {
  const { id } = useParams();
  const navigate = useNavigate();

  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("Card");
  const [selectedPrice, setSelectedPrice] = useState(null);
  const [fawryAvailable, setFawryAvailable] = useState(false);
  const [installmentsPaid, setInstallmentsPaid] = useState(0);
  const [popupOpen, setPopupOpen] = useState(false);

  const { data: installmentPlansPayment } = useFetch(
    `${paymentStatusEndpoint}${id}`
  );
  const { data: stripeData } = useFetch(
    `${paymentPriceEndpoint}?program_id=${id}&installment_plan=${installmentPlansPayment?.total_installments}&payment_method=stripe`
  );

  const { data: fawryData } = useFetch(
    `${paymentPriceEndpoint}?program_id=${id}&installment_plan=${installmentPlansPayment?.total_installments}&payment_method=fawry`
  );

  useEffect(() => {

    if (fawryData?.fawry_available !== undefined) {
      setFawryAvailable(fawryData.fawry_available);
    }
    if (installmentPlansPayment?.total_installments_paid !== undefined) {
      setInstallmentsPaid(installmentPlansPayment?.total_installments_paid);
    }
  }, [stripeData, fawryData, installmentPlansPayment]);

  const handlePayNow = () => {
    if (selectedPaymentMethod === "Card") {
      payWithStripe(id, installmentPlansPayment?.total_installments, installmentsPaid);
    } else if (selectedPaymentMethod === "Fawry") {
      navigate(`/payment/${id}/${installmentPlansPayment?.total_installments}`);
    } else if (selectedPaymentMethod === "Bank Transfer") {
      setPopupOpen(true);
    }
  };

  return (
    <>
      <div className="flex justify-center items-center h-[70vh]">

        <div className="w-full lg:w-1/2 p-6 border rounded-lg shadow-md">
          <h3 className="text-lg font-medium mb-5 text-center">Continue your Payment</h3>
          <p className="text-2xl text-green-600 text-center font-bold border w-[25%] m-auto rounded p-2">
            ${installmentPlansPayment?.program_price || "Loading..."}
          </p>

          <div className="mt-8 mb-3">

            <h3 className="text-lg font-medium mb-5 text-center">Payment methods:</h3>
            <div className="flex gap-4 justify-center">
              <label
                className={`flex items-center p-3 rounded-lg cursor-pointer ${selectedPaymentMethod === "Card"
                  ? "border-2 border-green-500 bg-green-50 text-green-500"
                  : "border border-gray-300 text-gray-400"
                  }`}
              >
                <input
                  type="radio"
                  name="paymentMethod"
                  value="Card"
                  checked={selectedPaymentMethod === "Card"}
                  onChange={() => setSelectedPaymentMethod("Card")}
                  className="hidden"
                />
                <CardIcon
                  className="mr-2 w-[1.5rem] h-[1.5rem]"
                  color={selectedPaymentMethod === "Card" ? "#40A353" : "#CDD5E0"}
                />
                Card
              </label>

              <label
                className={`flex items-center p-3 rounded-lg cursor-pointer ${selectedPaymentMethod === "Bank Transfer"
                  ? "border-2 border-green-500 bg-green-50 text-green-500"
                  : "border border-gray-300 text-gray-400"
                  }`}
              >
                <input
                  type="radio"
                  name="paymentMethod"
                  value="Bank Transfer"
                  checked={selectedPaymentMethod === "Bank Transfer"}
                  onChange={() => setSelectedPaymentMethod("Bank Transfer")}
                  className="hidden"
                />
                <BankIcon
                  className="mr-2 w-[1.5rem] h-[1.5rem]"
                  color={selectedPaymentMethod === "Bank Transfer" ? "#40A353" : "#CDD5E0"}
                />
                Bank Transfer
              </label>

              {fawryAvailable && (<label
                className={`flex items-center p-3 rounded-lg cursor-pointer ${selectedPaymentMethod === "Fawry"
                  ? "border-2 border-green-500 bg-green-50 text-green-500"
                  : "border border-gray-300 text-gray-400"
                  }`}
              >
                <input
                  type="radio"
                  name="paymentMethod"
                  value="Fawry"
                  checked={selectedPaymentMethod === "Fawry"}
                  onChange={() => setSelectedPaymentMethod("Fawry")}
                  className="hidden"
                />
                <FawryIcon
                  className="mr-2 w-[1.5rem] h-[1.5rem]"
                  color={selectedPaymentMethod === "Fawry" ? "#40A353" : "#CDD5E0"}
                />

              </label>)}

            </div>
          </div>

          <button
            className="w-[85%] m-auto mt-6 p-3 rounded-lg bg-green-500 text-white text-lg font-semibold hover:bg-green-600 disabled:bg-gray-300 disabled:cursor-not-allowed"
            onClick={handlePayNow}
            disabled={selectedPaymentMethod === "Bank Transfer"}
          >
            Continue
          </button>
        </div>
      </div>

      <BankTransferPopup open={popupOpen} onClose={() => setPopupOpen(false)} />
    </>
  );
}

export default ContinueInstallment;
