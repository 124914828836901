import React, { useState } from "react";
import { NavLink, Outlet, useNavigate } from "react-router-dom";
import styles from "./Layout.module.css";
import GroupsIcon from '@mui/icons-material/Groups';
import LoginIcon from '@mui/icons-material/Login';
import MenuIcon from '@mui/icons-material/Menu'; // Import menu icon
import calenderProgram from '../../assets/Calendar.svg';

export default function Layout() {
  const [sidebarOpen, setSidebarOpen] = useState(false); // State for sidebar toggle
  const navigate = useNavigate();
  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };
  const handleLogout = () => {
    // Remove tokens from local storage
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('is_staff');
    localStorage.removeItem('first_name');
    // setIsLoggedIn(false); // Update state to reflect logout
    navigate('/login'); // Redirect to home page
  };
  return (
    <>
      {/* Toggle Button for Mobile */}
      <button className={styles.menuButton} onClick={toggleSidebar}>
        <MenuIcon />
      </button>

      <div className={`${styles.dashboardContainer} ${sidebarOpen ? styles.openSidebar : ''}`}>
        <div className={`${styles.tabsContainer} ${sidebarOpen ? styles.showSidebar : ''}`}>
          <ul className={styles.tabsNavigation}>
            <NavLink
              to="students"
              className={({ isActive }) =>
                `${styles.navigateContainer} ${isActive ? styles.active : styles.notActive}`
              }
            >
              <div className={styles.navItem}>
                <GroupsIcon />
                <p>Payments</p>
              </div>
            </NavLink>
            <NavLink
              to="programs"
              className={({ isActive }) =>
                `${styles.navigateContainer} ${isActive ? styles.active : styles.notActive}`
              }
            >
              <div className={styles.navItem}>
                <img src={calenderProgram} alt="calender for programs" />
                <p>Programs</p>
              </div>
            </NavLink>
          </ul>
          <button onClick={() => { handleLogout(); }} className={styles.logout}>
            <p>Logout</p>
            <LoginIcon />
          </button>
        </div>
        <div style={{ padding: "20px 10px", width: "90%", minHeight: "100vh" }}>
          <Outlet />
        </div>
      </div>
    </>
  );
}
