import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useGetProgramPaymentStatus } from "../../hooks/useGetProgramPaymentStatus";
import PaymentPlan from "./PaymentPlan";
import PaymentMethodSelector from "./PaymentSelector";
import { payWithStripe } from "../../helpers/payWithStripe";
import { useFetch } from "../../hooks/useFetch";
import { paymentPriceEndpoint, paymentStatusEndpoint } from "../../Service/endpoints";
import BankTransferPopup from './BankTransferPopup';

const paymentPlans = [
  {
    title: "Pay in Full",
    installmentPlan: 1,
  },
  {
    title: "Pay in 2 Installments",
    installmentPlan: 2,
  },
  {
    title: "Pay in 4 Installments",
    installmentPlan: 4,
  },
];

const PaymentPlans = () => {
  const { id } = useParams();
  const paymentStatus = useGetProgramPaymentStatus(id);
  const [selectedPlan, setSelectedPlan] = React.useState(1);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = React.useState("Card");
  const [selectedPrice, setSelectedPrice] = React.useState(null);
  const [fawryAvailable, setFawryAvailable] = React.useState(false);
  const [popupOpen, setPopupOpen] = useState(false);
  const navigate = useNavigate();

  // Fetch Stripe payment price
  const { data: stripeData } = useFetch(
    `${paymentPriceEndpoint}?program_id=${id}&installment_plan=${selectedPlan}&payment_method=stripe`
  );

  // Fetch Fawry availability
  const { data: fawryData } = useFetch(
    `${paymentPriceEndpoint}?program_id=${id}&installment_plan=${selectedPlan}&payment_method=fawry`
  );
  const { data: installmentPlansPayment } = useFetch(
    `${paymentStatusEndpoint}${id}`
  );

  React.useEffect(() => {
    if (stripeData?.final_price) {
      setSelectedPrice(stripeData.final_price);
    }
    if (fawryData?.fawry_available !== undefined) {
      setFawryAvailable(fawryData.fawry_available);
    }
  }, [stripeData, fawryData]);



  const handlePayNow = () => {
    if (selectedPaymentMethod === "Card") {
      payWithStripe(id, selectedPlan, installmentPlansPayment.total_installments_paid);
    } else if (selectedPaymentMethod === "Fawry") {
      navigate(`/payment/${id}/${selectedPlan}`);

    } else if (selectedPaymentMethod === "Bank Transfer") {
      setPopupOpen(true);
    }
  };

  return (
    <>
      <div className="custom-container py-10 flex flex-col justify-between lg:flex-row items-center gap-8 h-[80vh]">
        <div className="w-full lg:w-[40%]">
          <div className="space-y-4">
            {paymentPlans.map((plan) => (
              <PaymentPlan
                key={plan.installmentPlan}
                plan={plan}
                programPrice={paymentStatus?.program_price}
                programId={id}
                selectedPlan={selectedPlan}
                onSelect={setSelectedPlan}
              />
            ))}
          </div>
        </div>

        <PaymentMethodSelector
          selectedPaymentMethod={selectedPaymentMethod}
          setSelectedPaymentMethod={setSelectedPaymentMethod}
          handlePayNow={handlePayNow}
          selectedPlan={selectedPlan}
          price={selectedPrice}
          fawryAvailable={fawryAvailable}
          fawryData={fawryData}
        />
      </div>
      <BankTransferPopup open={popupOpen} onClose={() => setPopupOpen(false)} />
    </>

  );
};

export default PaymentPlans;

