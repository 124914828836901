import { apiPostPayWithStripe } from "../Service/apiPostPayWithStripe";

export const payWithStripe = async (
  programId,
  installmentPlan,
  totalInstallmentPaid
) => {
  try {
    const response = await apiPostPayWithStripe(
      programId,
      installmentPlan,
      totalInstallmentPaid
    );
    window.location.href = response.data.url;
  } catch (err) {
    console.log(err);
  }
};
